import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import "./styles.sass";

import coursesImage2 from "../../assets/images/courses12.jpg";
import coursesImage3 from "../../assets/images/courses132.jpg";
import coursesImage1 from "../../assets/images/mishena3.jpg";

import LibraryCard from "../../components/UI/LibraryCard";
import PagesIntro from "../../components/UI/PagesIntro";
import Modules from "../../components/Course/Modules/Modules";

import { selectCourse, selectCourses } from "../../store/selectors/courses";
import {
  getCourse,
  setPurchasePrice,
  setPurchaseProducts,
} from "../../store/courses";

import { formatPrice } from "../../utils";

const CoursePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [recomCourses, setRecomCourses] = useState([]);

  const { list: courses } = useSelector(selectCourses);
  const { data: course, loading } = useSelector(selectCourse);

  const handleBuyCourse = () => {
    if (Cookies.get("access_token")) {
      const products = [
        {
          productType: "course",
          productId: course.id,
        },
      ];

      dispatch(setPurchasePrice(formatPrice(course.price)));
      localStorage.setItem("price", formatPrice(course.price));
      dispatch(setPurchaseProducts(products));
      navigate("/payment");
    } else {
      navigate("/auth");
    }
  };

  useEffect(() => {
    dispatch(getCourse(id));
  }, []);

  useEffect(() => {
    setRecomCourses(courses.filter((el) => el.id !== Number(id)));
  }, [course]);

  if (loading || !course) return <div>Yuklanmoqda</div>;
  return (
    <div className="coursesPage">
      <div className="container">
        <div className="coursesPageContainer">
          <PagesIntro
            introIcon="icon-Notebook"
            introTitle="Onlayn kurs"
            introText="Yangi kasbni o‘rganish va dunyoning istalgan nuqtasidan bilim olishning arzon va qulay usuli."
          ></PagesIntro>

          <div className="pagesData">
            <div className="coursesInfo">
              <div className="infoContainer">
                <div className="coursesHeader">
                  <div>
                    <div className="headerTitle">
                      <h3 className="title">{course.title}</h3>
                    </div>
                    <div className="headerSubtitle">
                      <p className="subTitle">{course.description}</p>
                    </div>
                  </div>
                  <div className="buyBtnWithTextWrapper">
                    <h4>{formatPrice(course.price)} so'm</h4>
                    <button onClick={handleBuyCourse}>Kurs sotib oling</button>
                  </div>
                </div>
                <div className="coursesBody">
                  <div className="imageBox">
                    <img
                      src={coursesImage1}
                      alt="coursesImage"
                      className="img"
                    />
                  </div>
                  <div className="imageBox">
                    <img
                      src={coursesImage2}
                      alt="coursesImage"
                      className="img"
                    />
                  </div>
                  <div className="imageBox">
                    <img
                      src={coursesImage3}
                      alt="coursesImage"
                      className="img"
                    />
                  </div>
                </div>
                <div className="coursesFooter">
                  <div className="footerDuration">
                    <p className="duration">{course.time}</p>
                  </div>
                  <div className="teacherName">
                    <p className="name">{course.author}</p>
                  </div>
                </div>
              </div>
            </div>

            <Modules modules={course.modules} />

            <div className="coursesCards">
              {/* {recomCourses.map((course, index) => (
                <LibraryCard
                  key={index}
                  image={course.photoUrls[0]}
                  alt={course.title}
                  contentText={course.title}
                  iconDuration="icon-Play"
                  durationText={course.courseDuration}
                  totalNumber={course.title}
                  btnText={"Подробнее"}
                  btnLink={`/courses/${course.id}`}
                  infoText={course.subtitle}
                />
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
