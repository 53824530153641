import Cookies from "js-cookie";

import instance from "./instance";

export const authApi = async (code) => {
  const res = await instance
    .post("auth/verify-phone-code", {
      phoneNumber: Cookies.get("phoneNumber"),
      code: code.join(""),
    })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        Cookies.set("access_token", res.data.access_token);
        return res;
      } else {
        throw new Error("autent emas");
      }
    });

  return res;
};

export const registerApi = async (values) => {
  const res = await instance.post("auth/register", {
    fullName: values.fullName,
    email: values.email,
    phoneNumber: values.phoneNumber.replace(/[^\d+]/g, ""),
    gender: values.gender,
    city: values.city,
    birthday: values.birthday,
    password: values.password,
  });

  return res;
};

export const phoneCodeApi = async () => {
  const res = await instance.post("auth/send-phone-code", {
    phoneNumber: Cookies.get("phoneNumber"),
  });
  return res;
};

export const loginApi = async (values) => {
  const res = await instance
    .post("auth/login", {
      phoneNumber: values.phoneNumber.replace(/[^\d+]/g, ""),
      password: values.password,
    })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        Cookies.set("access_token", res.data.access_token, { expires: 1 });
        return res;
      } else {
        throw new Error("login emas");
      }
    });

  return res;
};
