import React from "react";
import { Link } from "react-router-dom";
import coursesImage2 from "../../assets/images/courses12.jpg";
import coursesImage3 from "../../assets/images/courses132.jpg";
import firstTutor from "../../assets/images/mishena2.jpg";
import Button from "../UI/Button";
import "./styles.sass";

const OurTeachers = () => {
  const teacherCard = [
    {
      id: 1,
      img: coursesImage2,
      alt: "firstTutor",
      name: "Emili Karter",
      text: "2012 yildan beri sovuq qo‘ng‘iroqlar bo‘yicha mutaxassis. Savdo, muzokaralar va mijozlar bilan munosabatlarni boshqarish bo‘yicha mutaxassis",
      disabled: false,
      // imageTimer: timerImage,
    },
    {
      id: 2,
      img: firstTutor,
      alt: "firstTutor",
      name: "Mishina Lyudmila",
      // text: "Lorem ipsum dolor sit amet, consectetur adipising elit, sed do eiusmod tempor",
      text: "2007 yildan beri sotuvlar, muzokaralar, xodimlarni boshqarish bo‘yicha biznes-murabbiy. 2012 yildan beri mutaxassislarni tayyorlash va rivojlantirish bo‘yicha o‘quvlarda. ",
    },
    {
      id: 3,
      img: coursesImage3,
      alt: "firstTutor",
      text: "Kutilmoqda...",
      disabled: "disable",
      disabled: false,
      text: "2010 yildan beri mijozlarni qo‘llab-quvvatlash bo‘yicha mutaxassis. Savdo, muzokaralar va mijozlarni ushlab turish bo‘yicha ekspert.",
      // imageTimer: timerImage,
    },
  ];
  return (
    <section
      className='teachers'
      id='teachers'
    >
      <div className='container'>
        <div className='sectionTitleBlack'>
          <h3 className='title'>Bizning o‘qituvchilar</h3>
        </div>
        <div className='cardsContainer'>
          {teacherCard.map((item, index) => (
            <div
              className='teacherCard'
              key={index}
            >
              <div className='cardImage'>
                <img
                  src={item.img}
                  alt={item.name}
                  className='img'
                />
              </div>
              <div className='cardContent'>
                <div className='cardTitile'>
                  <h4 className='title'>{item.name}</h4>
                </div>
                {item.imageTimer && (
                  <div className='cardImageTimer'>
                    <img
                      src={item.imageTimer}
                      alt='timer'
                    />
                  </div>
                )}
                <div className='cardText'>
                  <p className='text'>{item.text}</p>
                </div>
              </div>

              <Link
                to={`/courses/${item.id}`}
                className='cardBtn'
              >
                <Button disabled={item.disabled}>Batafsil</Button>
              </Link>
            </div>
          ))}
        </div>
        <div className='skroll-btn'>
          <a
            href='#price'
            className='skrollContent'
          >
            <div className='skrollText'>
              <span className='text'> Proskill Academy </span>
            </div>
            <div className='skrollTitle'>
              <h3 className='title'> bilan maqsadlaringizga erishing</h3>
            </div>
          </a>
          <a
            href='#price'
            className='arrowDown'
          >
            <i className='icon-Play'></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default OurTeachers;
