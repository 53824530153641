import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCourseApi, getCoursesApi } from "../api/courses";

export const getCourses = createAsyncThunk("courses/getCourses", async () => {
  const res = await getCoursesApi();
  return res;
});

export const getCourse = createAsyncThunk("courses/getCourse", async (id) => {
  const res = await getCourseApi(id);
  return res;
});

const initialState = {
  courses: {
    list: [],
    loading: false,
  },
  course: { data: null, loading: false },
  purchase: {
    price: 0,
    products: [],
  },
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setPurchasePrice: (state, { payload }) => {
      state.purchase.price = payload;
    },
    setPurchaseProducts: (state, { payload }) => {
      state.purchase.products = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourses.fulfilled, (state, { payload }) => {
        state.courses.list = payload;
        state.loading = false;
      })
      .addCase(getCourses.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourse.fulfilled, (state, { payload }) => {
        state.course.data = payload;
        state.loading = false;
      })
      .addCase(getCourse.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default coursesSlice.reducer;
export const { setPurchasePrice, setPurchaseProducts } = coursesSlice.actions;
