import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.sass";

import PaymentVerificationInput from "./PaymentVerification";
import Timer from "../Timer";
import Button from "../UI/Button";

import { selectPurchaseProducts } from "../../store/selectors/courses";
import { sendPaymentOtp } from "../../store/clickPaymentSlice";

const PaymentOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [filled, setFilled] = useState(0);

  const isPurchased = useSelector((state) => state.clickPayment?.isPurchased);
  const products = useSelector(selectPurchaseProducts);

  useEffect(
    (e) => {
      if (filled === 5) {
        handleSubmit(e);
      }
    },
    [filled],
  );
  useEffect(() => {
    if (isPurchased) navigate("/");
  }, [isPurchased]);

  useEffect(() => {
    if (products.length === 0) navigate("/");
  }, []);

  const handleSubmit = () => {
    dispatch(sendPaymentOtp({ otp, products }));
    window.location.herf = "http://proskill-academy.net/";
  };

  return (
    <div className='paymentOtp'>
      <div className='container'>
        <div className='otpContainer'>
          <div className='otpHeader'>
            <div className='headerTitle'>
              <h3 className='title'>SMS orqali kodni tasdiqlash talab qilinadi</h3>
            </div>
            <div className='headerSubtitle'>
              <p className='subtitle'>Telefon raqamingizga tasdiqlash kodi yuborildi</p>
            </div>
          </div>
          <div className='otpBody'>
            <div className='bodyTitle'>
              <h4 className='title'>SMS kodini kiriting</h4>
            </div>
            <form
              action=''
              className='otpVerificationForm'
              onSubmit={handleSubmit}
            >
              <div className='verificationCard'>
                <PaymentVerificationInput
                  otp={otp}
                  setOtp={setOtp}
                  submit={handleSubmit}
                  setFilled={setFilled}
                />
              </div>
              <div className='btnGroup'>
                <Link to='/auth'>
                  <Button className='outline'>Orqaga</Button>
                </Link>
                <Button>Tasdiqlash</Button>
              </div>
            </form>
          </div>
          <Timer />
        </div>
      </div>
    </div>
  );
};

export default PaymentOtp;
