export const CoursesData = [
  {
    id: 1,
    courseInfo: {
      isActive: true,
      title: "Xizmat ko'rsatish bo'yicha mutaxassis",
      subtitle:
        "Become proficient in front-end web development. Learn HTML, CSS, JavaScript, and popular frameworks like Bootstrap and React. Build interactive and responsive websites.",
      secondImage: "",
      thirdImage: "",
      courseDuration: "10 haftalar",
      courseTeaxher: "Emili Karter",
    },
    coursesList: [
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 01",
        duration: "45 daqiqa",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 02",
        duration: "1 soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 03",
        duration: "45 daqiqa",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 04",
        duration: "1 soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 05",
        duration: "1 soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 06",
        duration: "45 daqiqa",
      },
    ],
    price: {
      lesson: {
        price: 99000,
        includes: [
          {
            value: "1 Dars",
          },
          {
            value: "1 PDF fayl",
          },
          {
            value: "5 audio yozuvlar",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
      module: {
        price: 499000,
        includes: [
          {
            value: "6-9 video kurslar",
          },
          {
            value: "10 PDF fayl ",
          },
          {
            value: "50 audio yozuvlar",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
      course: {
        price: 1999000,
        includes: [
          {
            value: "9 Modullar",
          },
          {
            value: "48 video kurslar",
          },
          {
            value: "48 PDF fayl",
          },
          {
            value: "1000 audio yozuvlar",
          },
          {
            value: "Kompaniyadan maxfiy sovg'a",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
    },
  },
  {
    id: 2,
    courseInfo: {
      isActive: true,
      title: "Savdo, muzokaralar, xodimlarni boshqarish",
      subtitle:
        "Ko'p va doimiy daromad olish, martaba pog'onasida o'sish, menejeringiz bilan yaxshi munosabatda bo'lish, qimmatli, qimmat va talabga ega bo'lgan mutaxassis bo'lish uchun siz savdodagi natijalaringizga qanday ta'sir qilishni o'rganasiz va o'rganasiz.",
      secondImage: "",
      thirdImage: "",
      courseDuration: "10 haftalar",
      courseTeaxher: "Mishina Lyudmila",
    },
    coursesList: [
      {
        title: "Kasbning afzalliklari ",
        subtitle: "Dars 01",
        duration: "45 daqiqa",
      },
      { title: "Uni sotish", subtitle: "Dars 02", duration: "1 soat" },
      {
        title: "Savdo bosqichlari va maqsadlari",
        subtitle: "Dars 03",
        duration: "45 daqiqa",
      },
      {
        title: "Samaradorlik va samaradorlik",
        subtitle: "Dars 04",
        duration: "1 soat",
      },
      { title: "Sotish huni", subtitle: "Dars 05", duration: "soat" },
      { title: "Tekshirish roʻyxati", subtitle: "Dars 06", duration: "45 daqiqa" },
      { title: "Skript", subtitle: "Dars 06", duration: "45 daqiqa" },
      { title: "Qo'ng'iroqlarni tahlil qilish ", subtitle: "Dars 06", duration: "45 daqiqa" },
    ],
    price: {
      lesson: {
        price: 99000,
        includes: [
          {
            value: "1 dars",
          },
          {
            value: "1 PDF fayli",
          },
          {
            value: "5 audio yozuvlar",
          },
          {
            value: "O‘qituvchi",
          },
        ],
      },
      module: {
        price: 499000,
        includes: [
          {
            value: "6-9 video kurslar",
          },
          {
            value: "10 PDF fayllari",
          },
          {
            value: "50 audio yozuvlar",
          },
          {
            value: "O‘qituvchi",
          },
        ],
      },
      course: {
        price: 1999000,
        includes: [
          {
            value: "9 modullar",
          },
          {
            value: "48 videolar",
          },
          {
            value: "48 PDF fayllari",
          },
          {
            value: "1000 audio yozuvlar",
          },
          {
            value: "+ kompaniyadan maxfiy sovg‘a",
          },
          {
            value: "O‘qituvchi",
          },
        ],
      },
    },
  },
  {
    id: 3,
    courseInfo: {
      isActive: true,
      title: "SBB - savdo bo'limi boshlig'i",
      subtitle:
        "Become proficient in front-end web development. Learn HTML, CSS, JavaScript, and popular frameworks like Bootstrap and React. Build interactive and responsive websites.",
      secondImage: "",
      thirdImage: "",
      courseDuration: "10 haftalar",
      courseTeaxher: "Maykl Adams tomonidan",
    },
    coursesList: [
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 01",
        duration: "45 daqiqa",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 02",
        duration: "1 soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 03",
        duration: "45 daqiqa",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 04",
        duration: "1 soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 05",
        duration: "soat",
      },
      {
        title: "Understanding UI/UX Design Principles",
        subtitle: "Dars 06",
        duration: "45 daqiqa",
      },
    ],
    price: {
      lesson: {
        price: 99000,
        includes: [
          {
            value: "1 Dars",
          },
          {
            value: "1 PDF fayl",
          },
          {
            value: "5 audio yozuvlar",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
      module: {
        price: 499000,
        includes: [
          {
            value: "6-9 video kurslar",
          },
          {
            value: "10 PDF fayllar",
          },
          {
            value: "50 audio yozuvlar",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
      course: {
        price: 1999000,
        includes: [
          {
            value: "9 Modullar",
          },
          {
            value: "48 video yozuvlar",
          },
          {
            value: "48 PDF fayllar",
          },
          {
            value: "1000 audio yozuvlar",
          },
          {
            value: "Kompaniyadan maxfiy sovg'a",
          },
          {
            value: "O'qituvchi",
          },
        ],
      },
    },
  },
];
