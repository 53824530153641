import React, { useState } from "react";
import Checkbox from "../CheckBox";
import testImage from "../../../assets/images/testImage.png";
import Button from "../Button";
import finishImage from "../../../assets/images/finishTest.png";
import "./styles.sass";

const TestCard = ({ onClick }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [checkboxData, setCheckboxData] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const tests = [
    {
      question: " Yangi odamlar bilan muloqot qilish kerak bo'lganda o'zingizni qanday his qilasiz?",
      options: [
        "Qulay va ishonchli",
        "Men biroz asabiyman, lekin bardoshim bor",
        "Men qattiq noqulaylik his qilyapman",
      ],
    },
    {
      question: "Rad etishlar va muvaffaqiyatsizliklar bilan qanday kurashasiz?",
      options: [
        "Men ularni jarayonning bir qismi sifatida qabul qilaman va ulardan o'rganaman.",
        "Ba'zida xafa bo'laman, lekin tezda o'zimga kelaman",
        "Men juda qiyin vaqtni boshdan kechiryapman va motivatsiyani yo'qotyapman.",
      ],
    },
    {
      question: "Ishontirish qobiliyatingizni qanday baholaysiz?",
      options: [
        "Zo'r, men odamlarni osongina ishontira olaman",
        "O'rtacha, ba'zan muvaffaqiyatli, ba'zan esa yo'q",
        "Zaif, boshqalarni ishontirish menga qiyin",
      ],
    },
    {
      question: "Multitasking bilan qanday shug'ullanasiz?",
      options: [
        "Oson, men bir nechta vazifani bajarishni yaxshi ko'raman",
        "Men bardosh beraman, lekin ba'zida stressga duchor bo'laman",
        "Qiyin, bir vaqtning o'zida bitta vazifani bajarishni afzal ko'radi",
      ],
    },
    {
      question: " Ishda motivatsiya siz uchun qanday rol o'ynaydi?",
      options: [
        "Juda muhim, men har doim o'zimni rag'batlantirish yo'llarini topaman",
        "Muhim, lekin ba'zida menga tashqi yordam kerak",
        "Vaziyatga qarab, motivatsiya ko'pincha tushadi",
      ],
    },
    {
      question: "Raqobatga qanday munosabatdasiz?",
      options: [
        "Bu meni rag'batlantiradi va rag'batlantiradi",
        "Men buni qabul qilaman, lekin ba'zida bosimni his qilaman",
        "Stress va ishonchsizlik hissi",
      ],
    },
    {
      question: "Belgilangan rejalar va maqsadlar sari ishlashga qanday qaraysiz?",
      options: [
        "Men reja asosida ishlashni va maqsadlarga erishishni yaxshi ko'raman",
        "Men unga sodiq qolishga harakat qilaman, lekin ba'zida rejadan chetga chiqaman",
        "Menga belgilangan rejalarga amal qilish qiyin",
      ],
    },
    {
      question: " Ishingizda qanchalik tartiblisiz?",
      options: [
        "Juda tartibli, har doim jadvalga rioya qiling",
        "Juda tashkillashtirilgan, lekin ba'zida tartibsizlik",
        "Yomon tashkillashtirilgan, men ko'pincha diqqatni yo'qotaman",
      ],
    },
    {
      question: "Jamoada ishlashda o'zingizni qanday his qilasiz?",
      options: [
        "Ajoyib, men muloqot qilishni va hamkorlik qilishni yaxshi ko'raman",
        "Yomon emas, lekin ba'zida mustaqil ishlashni afzal ko'raman",
        "Bu qiyin, men uchun yolg'iz ishlash osonroq",
      ],
    },
    {
      question: "Ko'p pul topish siz uchun qanchalik muhim?",
      options: [
        "Juda muhim, bu mening asosiy motivatsiyam",
        "Muhim, lekin birinchi o'rinda emas",
        "Ayniqsa muhim emas, asosiysi qiziqarli ish",
      ],
    },
  ];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setCheckboxData((prevData) => {
      const newData = [...prevData];
      newData[currentCardIndex] = value;
      return newData;
    });
  };

  const handleNextCard = () => {
    setCurrentCardIndex((prevIndex) => prevIndex + 1);
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  const renderTestCard = () => {
    const currentTest = tests[currentCardIndex];
    return (
      <div className='testCard'>
        <div className='cardContainer'>
          <div className='cardHeader'>
            <div className='pageNumber'>
              <p className='number'>
                <span className='current'>{currentCardIndex + 1}</span>/10
              </p>
            </div>
            <div
              className='closeBtn'
              onClick={onClick}
            >
              <span className='close'></span>
            </div>
          </div>
          <div className='cardBody'>
            <div className='bodyTest'>
              <div className='testTitle'>
                <h4 className='title'>{currentTest?.question}</h4>
              </div>
              <div className='tests'>
                {currentTest.options.map((option) => (
                  <Checkbox
                    key={option}
                    htmlFor={option}
                    id={option}
                    value={option}
                    isChecked={checkboxData[currentCardIndex] === option}
                    onChange={handleCheckboxChange}
                    option={option}
                  />
                ))}
              </div>
            </div>
            {currentCardIndex < tests.length - 1 ? (
              <Button
                onClick={handleNextCard}
                disabled={!checkboxData[currentCardIndex]}
              >
                Следущий
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                // disabled={!checkboxData}
                disabled={!checkboxData[currentCardIndex]}
              >
                Сдать
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {!submitted && renderTestCard()}
      {submitted && (
        <div className='testCard testFinishCard'>
          <div className='cardContainer'>
            <div className='cardHeader'>
              <div
                className='closeBtn'
                onClick={onClick}
              >
                <span className='close'></span>
              </div>
            </div>
            <div className='cardBody'>
              <div className='bodyImage'>
                <img
                  src={finishImage}
                  alt='testImage'
                  className='img'
                />
              </div>
              <div className='cardTitle'>
                <h3 className='title'>Siz sinovdan o'tdingiz!</h3>
              </div>
              <div className='cardText'>
                <p className='text'>Sinov natijalari</p>
              </div>
              <div className='cardTextFinishBox'>
                <div className='boxTextContainer'>
                  <div className='boxTitle'>
                    <h4 className='title'>Sotish bo'yicha menejer</h4>
                  </div>
                  <div className='boxText'>
                    <p className='text'>
                    Savdo tamoyillari va asoslarini o'rganing, mijoz va uning ehtiyojlarini tushunishni o'rganing. Siz rivojlana olasiz
                    sizning muloqot va muzokara qobiliyatingiz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TestCard;
