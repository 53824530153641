import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

import s from "./index.module.scss";

import Lesson from "../Lesson/Lesson";

import { formatPrice } from "../../../utils";

const Module = ({ module, selected, selectedLessons, onModuleChange, onLessonChange }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggleModule = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
      <div className={s.card}>
        {module.price === 0 && (
          <div className={s.card_blured}>
            <h3>Sotib olingan</h3>
          </div>
        )}
        <div
          className={s.card_content}
          onClick={handleToggleModule}
        >
          <div className={s.content}>
            <h4>{module.title}</h4>
            <p>{module.lessons.length} dars</p>
            <p>Vaqt {module.time}</p>
          </div>
          <div className={s.control}>
            <p>{formatPrice(module.price)} so'm</p>
            <input
              type='checkbox'
              disabled={module.price == 0}
              checked={module.price === 0 || selected}
              onChange={() => onModuleChange(module.id)}
              onClick={(event) => event.stopPropagation()}
            />
            <ChevronDown
              style={{
                transform: `rotateZ(${isToggled ? "180deg" : "0deg"})`,
              }}
              className='ico'
            />
          </div>
        </div>
      </div>
      {isToggled && (
        <div className={s.lessonsList}>
          {module.lessons.map((lesson) => (
            <Lesson
              key={lesson.id}
              lesson={lesson}
              selected={!!selectedLessons[lesson.id]}
              onLessonChange={() => onLessonChange(module.id, lesson.id)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Module;
