import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import instance from "../api/instance";

export const clickPaymentData = createAsyncThunk(
  "clickPayment/clickPaymentData",
  async (values) => {
    try {
      const response = await instance
        .post("/click/card", {
          card_number: values.cardNumber.replace(/[^\d+]/g, ""),
          expire_date: values.expireDate.replace(/[^\d+]/g, ""),
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            Cookies.set(
              "card_number",
              values.cardNumber.replace(/[^\d+]/g, "")
            );
            return res;
          } else {
            throw new Error("sotib olinmagan");
          }
        });
      return response.data;
    } catch (error) {
      console.log("err", error.message);
    }
  }
);

export const sendPaymentOtp = createAsyncThunk(
  "clickPayment/sendPaymentOtp",
  async ({ otp, products }) => {
    try {
      const cardNumber = Cookies.get("card_number");
      const response = await instance
        .post("click/cardOTP", {
          sms_code: otp.join(""),
          card_number: cardNumber,
          products,
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Yangi xaridingiz bilan tabriklaymiz!");
            return res;
          } else {
            throw new Error("autent emas");
          }
        });
      toast.success("Xaridingiz bilan tabriklaymiz!");
      return response.data;
    } catch (error) {
      toast.error("Voy... nimadir xato ketdi");
      console.error(error);
    }
  }
);

const clickPaymentSlice = createSlice({
  name: "clickPayment",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    isPurchased: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clickPaymentData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(clickPaymentData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(clickPaymentData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(sendPaymentOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.isPurchased = false;
      })
      .addCase(sendPaymentOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.isPurchased = true;
        state.error = null;
      })
      .addCase(sendPaymentOtp.rejected, (state, action) => {
        state.status = "failed";
        state.isPurchased = false;
        state.error = action.error.message || "OTP yuborilmadi";
      });
  },
});

export default clickPaymentSlice.reducer;
