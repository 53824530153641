import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import authImage from "../../assets/images/auth4.png";
import RegistrationForm from "./RegistrationForm";
import LogInForm from "./logInForm";
import "./styles.sass";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AuthPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get("tab") || "login";
  const [value, setValue] = React.useState(tab === "register" ? 0 : 1);
  const phoneMask = "+998 (00) 000-00-00";
  const Mask = [
    {
      mask: phoneMask,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "white",
            },
          },
        },
      },
    },
  });

  // const [value, setValue] = React.useState();

  return (
    <div className='authPage'>
      <div className='container'>
        <div className='authPageContainer'>
          <div className='authPageImage'>
            <div className='blob first'></div>
            <div className='blob third'></div>
            <div className='blob second'></div>
            <div className='blob first'></div>
            <p className='text'>Bilim olishga shoshiling!</p>
            <img
              src={authImage}
              alt='authImage'
              className='img'
            />
          </div>
          <div className='tabsBlock'>
            <div className='blob second'></div>
            <ThemeProvider theme={theme}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                    className='tabs'
                    centered
                    TabIndicatorProps={{ style: { backgroundColor: "white" } }}
                    sx={{
                      display: "flex",
                      background: "#014A3F",
                      borderRadius: "10px",
                      padding: "10px 20px",
                    }}
                  >
                    <Tab
                      label='Roʻyxatdan oʻtish'
                      {...a11yProps(0)}
                      sx={{
                        color: "#fff",
                        ":hover": {
                          color: "#fff",
                        },
                        "::selection": {
                          color: "#fff",
                        },
                      }}
                    />
                    <Tab
                      label='Kirish'
                      {...a11yProps(1)}
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Tabs>
                </Box>
                <div className='blob first'></div>
                {value === 0 && (
                  // <CustomTabPanel
                  //   value={value}
                  //   index={0}
                  // >
                  <RegistrationForm />
                  // </CustomTabPanel>
                )}
                {value === 1 && (
                  // <CustomTabPanel
                  //   value={value}
                  //   index={1}
                  // >
                  <LogInForm />
                  // </CustomTabPanel>
                )}
              </Box>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
