import React from "react";
import Button from "../UI/Button";
import coursesBg from "../../assets/images/students2.png";
import firstTutor from "../../assets/images/mishena.jpg";
import "./styles.sass";
import { Link } from "react-router-dom";

const CoursesInformation = () => {
  return (
    <section
      className='coursesInformation'
      id='coursesInformation'
    >
      <div className='container'>
        <div className='coursesContainer'>
          <div className='coursesInfo'>
            <div className='infoHeader'>
              <div className='sectionTitleBlack'>
                <h3 className='title'>Ta’limga zamonaviy yondashuv</h3>
              </div>
            </div>
            <div className='infoBody'>
              <div className='bodyBlock'>
                <div className='blockTitle'>
                  <h3 className='title'>Mishina Lyudmila</h3>
                </div>
                <div className='blockText'>
                  <p className='text'>
                    Hamkasblar va mijozlar men haqimda shunday deyishadi: "uchqunli murabbiy. Men uch bosqichda sotish,
                    sotishni boshqarish, sotish bo‘yicha treninglar uchun Ilhom olovini yoqishga yordam beraman:
                    <ol>
                      <li>To‘siqlarni olib tashlash</li>
                      <li>O‘z ilhomini topish</li>
                      <li>
                        {" "}
                        Oqim holatida sotishni bir necha bor oshirish uchun mahoratni shakllantirish
                        <br /> (o‘rtacha 2 marta)
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <Link
              to='/courses/1'
              className='inforFooter'
            >
              <Button>Kurslar</Button>
            </Link>
          </div>
          <div className='coursesImagesGroup'>
            <div className='mainImageInformation'>
              <img
                src={coursesBg}
                alt='coursesBg'
                className='img'
              />
            </div>
            <div className='imagesContainer'>
              <div className='imageBox'>
                <img
                  src={firstTutor}
                  alt='firstTutor'
                  className='img'
                />
              </div>
              <div className='glass'>
                <h3 className='title'>Sotish bo‘yicha Biznes-murabbiy</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoursesInformation;
