import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import click from "../../../assets/images/Click 1.png";
import payme from "../../../assets/images/payme 1.png";
import ofb from "../../../assets/images/OFB 1.png";
import uzum from "../../../assets/images/Uzum 1.png";
import rcd from "../../../assets/images/rcd 1.png";
import euphoria from "../../../assets/images/euphoria13.png";
import arbit from "../../../assets/images/arbit 1.png";
import phoenix from "../../../assets/images/phoenixLogo1.png";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "./styles.sass";

const PartnersSlider = () => {
  const PartnersSlideImages = [
    { id: 1, img: click, alt: "click" },
    { id: 2, img: payme, alt: "payme" },
    { id: 4, img: ofb, alt: "ofb" },
    { id: 5, img: uzum, alt: "uzum" },
    { id: 1, img: rcd, alt: "rcd" },
    { id: 2, img: euphoria, alt: "euphoria" },
    { id: 3, img: arbit, alt: "arbit" },
    { id: 4, img: ofb, alt: "ofb" },
    { id: 5, img: uzum, alt: "uzum" },
    { id: 8, img: phoenix, alt: "phoenix" },
  ];

  return (
    <>
      <Swiper
        spaceBetween={50}
        className='partnersSlider'
        slidesPerView={6}
        loop={true}
        autoplay={{ delay: 100 }}
        freeMode={true}
        modules={[Autoplay]}
        speed={1200}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          470: {
            slidesPerView: 3,
          },
          577: {
            slidesPerView: 3,
          },
          911: {
            slidesPerView: 4,
          },
          1100: {
            slidesPerView: 6,
          },
        }}
      >
        {PartnersSlideImages.map((item, index) => (
          <SwiperSlide key={index}>
            <div className='imagesBlock'>
              <img
                src={item.img}
                alt={item.alt}
                className='img'
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PartnersSlider;
