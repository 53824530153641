import Cookies from "js-cookie";
import instance from "./instance";

export const getCoursesApi = async () => {
  const res = await instance.get(
    Cookies.get("access_token") ? "/courses/forUser" : "/courses"
  );
  return res.data;
};

export const getCourseApi = async (id) => {
  const res = await instance.get(
    Cookies.get("access_token")
      ? `/courses/forUser/${id}`
      : `/courses/one/${id}`
  );
  return res.data;
};
