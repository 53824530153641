import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Rights from "./Rights";
import logo from "../../assets/images/proSkillWhiteLogo.svg";
import "./styles.sass";

const Footer = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/auth" || location.pathname === "/otp" ? (
        <></>
      ) : (
        <footer className='footer'>
          <div className='container'>
            <div className='footerContainer'>
              <div className='footerItems'>
                <div className='footerItem'>
                  <div className='footerLogo'>
                    <Link
                      to='./'
                      className='logo'
                    >
                      <img
                        src={logo}
                        alt='logo'
                      />
                    </Link>
                  </div>
                  <div className='footerText'>
                    <p className='text'>Sizning profesional cho‘qqilarga yo‘lingiz</p>
                  </div>
                  <div className='footerIcons'>
                    <Link
                      to='https://t.me/proskill_uz'
                      className='icon'
                    >
                      <i className='icon-telegram'></i>
                    </Link>
                    <Link
                      to='https://www.instagram.com/proskill_uz?igsh=YTN4YXcyanQ3Njlo'
                      className='icon'
                    >
                      <i className='icon-instagram'></i>
                    </Link>
                    {/* <Link
                      to=''
                      className='icon'
                    >
                      <i className='icon-facebook'></i>
                    </Link> */}
                    <Link
                      to='mailto:proskillacademychat@gmail.com'
                      className='icon'
                    >
                      <i className='icon-mail'></i>
                    </Link>
                  </div>
                </div>
                <div className='footerItem'>
                  <div className='itemTitle'>
                    <h4 className='title'>Onlayn kurslar</h4>
                  </div>
                  <div className='itemLinks'>
                    <NavLink
                      to='/about'
                      className={`link ${({ isActive }) => (isActive ? "active" : "")}`}
                    >
                      Biz haqimizda
                    </NavLink>
                    <NavLink
                      to='./library'
                      className={`link ${({ isActive }) => (isActive ? "active" : "")}`}
                    >
                      Kutubxona
                    </NavLink>
                    <NavLink
                      to='./news'
                      className={`link ${({ isActive }) => (isActive ? "active" : "")}`}
                    >
                      Yangiliklar
                    </NavLink>
                    <NavLink
                      to='./courses'
                      className={`link ${({ isActive }) => (isActive ? "active" : "")}`}
                    >
                      Kurslar
                    </NavLink>
                  </div>
                </div>

                <div className='footerItem'>
                  <div className='itemTitle'>
                    <h4 className='title'>Asosiy</h4>
                  </div>
                  {location.pathname === "/about" ||
                  location.pathname === "/courses" ||
                  location.pathname === "/news" ||
                  location.pathname === "/contacts" ||
                  location.pathname === "/library" ||
                  location.pathname === "/payment" ? (
                    <div className='itemLinks'>
                      <Link
                        to='/#partners'
                        className='link'
                      >
                        Bizning hamkorlarimiz
                      </Link>
                      <Link
                        to='/#manager'
                        className='link'
                      >
                        Savdo bo‘yicha Menejer
                      </Link>
                      <Link
                        to='/#benefits'
                        className='link'
                      >
                        Afzalliklar
                      </Link>
                      <Link
                        to='/#learning'
                        className='link'
                      >
                        Afzalliklar
                      </Link>
                    </div>
                  ) : (
                    <div className='itemLinks'>
                      <a
                        href='#partners'
                        className='link'
                      >
                        {" "}
                        Bizning hamkorlarimiz
                      </a>
                      <a
                        href='#manager'
                        className='link'
                      >
                        Savdo bo‘yicha Menejer
                      </a>
                      <a
                        href='#benefits'
                        className='link'
                      >
                        Afzalliklar
                      </a>
                      <a
                        href='#learning'
                        className='link'
                      >
                        O‘quv jarayoni
                      </a>
                    </div>
                  )}
                </div>
                <div className='footerItem'>
                  <div className='itemTitle'>
                    <h4 className='title'>Yana biz haqimizda</h4>
                  </div>
                  {location.pathname === "/about" ||
                  location.pathname === "/news" ||
                  location.pathname === "/courses" ||
                  location.pathname === "/contacts" ||
                  location.pathname === "/library" ||
                  location.pathname === "/payment" ? (
                    <div className='itemLinks'>
                      <Link
                        to='/#teachers'
                        className='link'
                      >
                        O‘qituvchilarimiz
                      </Link>
                      <Link
                        to='/#coursesInformation'
                        className='link'
                      >
                        Bizning yondashuvimiz
                      </Link>
                      <Link
                        to='/#reviews'
                        className='link'
                      >
                        Sharhlar
                      </Link>
                      <Link
                        to='/#contacts'
                        className='link'
                      >
                        So‘rov yuborish
                      </Link>
                    </div>
                  ) : (
                    <div className='itemLinks'>
                      <a
                        href='#teachers'
                        className='link'
                      >
                        O‘qituvchilarimiz
                      </a>
                      <a
                        href='#coursesInformation'
                        className='link'
                      >
                        Bizning yondashuvimiz
                      </a>
                      <a
                        href='#reviews'
                        className='link'
                      >
                        Sharhlar
                      </a>
                      <a
                        href='#contacts'
                        className='link'
                      >
                        So‘rov yuborish
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <Rights />
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
