import React, { useEffect, useState } from "react";

import s from "./index.module.scss";
import Module from "../Module/Module";
import Panel from "../Panel/Panel";

const Modules = ({ modules }) => {
  const [selectedModules, setSelectedModules] = useState({});
  const [selectedLessons, setSelectedLessons] = useState({});

  const isSomeSelected = () => {
    return Object.values(selectedLessons).some((el) => el === true);
  };

  const handleModuleChange = (moduleId) => {
    const newSelectedModules = {
      ...selectedModules,
      [moduleId]: !selectedModules[moduleId],
    };
    const newSelectedLessons = { ...selectedLessons };

    if (newSelectedModules[moduleId]) {
      const lessonsInModule = modules.find(
        (module) => module.id === moduleId
      ).lessons;

      lessonsInModule.forEach((lesson) => {
        newSelectedLessons[lesson.id] = true;
      });
    } else {
      const lessonsInModule = modules.find(
        (module) => module.id === moduleId
      ).lessons;

      lessonsInModule.forEach((lesson) => {
        delete newSelectedLessons[lesson.id];
      });
    }

    setSelectedModules(newSelectedModules);

    setSelectedLessons(newSelectedLessons);
  };

  const handleLessonChange = (moduleId, lessonId) => {
    const newSelectedLessons = {
      ...selectedLessons,
      [lessonId]: !selectedLessons[lessonId],
    };

    const lessonsInModule = modules.find(
      (module) => module.id === moduleId
    ).lessons;
    const allLessonsSelected = lessonsInModule.every(
      (lesson) => newSelectedLessons[lesson.id]
    );

    if (allLessonsSelected) {
      setSelectedModules((selectedModules) => ({
        ...selectedModules,
        [moduleId]: true,
      }));
    } else {
      setSelectedModules((selectedModules) => ({
        ...selectedModules,
        [moduleId]: false,
      }));
    }

    setSelectedLessons(newSelectedLessons);
  };

  useEffect(() => {
    modules.forEach((module) => {
      if (module.price === 0) {
        handleModuleChange(module.id);
        return;
      }
      module.lessons.forEach((lesson) => {
        if (lesson.price === 0) {
          handleLessonChange(module.id, lesson.id);
        }
      });
    });

    return () => {
      setSelectedLessons({});
      setSelectedModules({});
    };
  }, []);

  return (
    <>
      <div className={s.list}>
        {modules.map((module) => (
          <Module
            key={module.id}
            module={module}
            selected={!!selectedModules[module.id]}
            selectedLessons={selectedLessons}
            onModuleChange={handleModuleChange}
            onLessonChange={handleLessonChange}
          />
        ))}
      </div>
      {isSomeSelected() && (
        <Panel
          selectedModules={selectedModules}
          selectedLessons={selectedLessons}
        />
      )}
    </>
  );
};

export default Modules;
