import React, { useState } from "react";
import PagesIntro from "../../components/UI/PagesIntro";
import Button from "../../components/UI/Button";
import PaymeImage from "../../assets/images/payme.png";
import { IMaskInput } from "react-imask";
import "./styles.sass";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paymePaymentData } from "../../store/paymePaymentSlice";
import { selectPurchasePrice } from "../../store/selectors/courses";

const PaymePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const priceState = useSelector(selectPurchasePrice);

  const [cardData, setCardData] = useState({ cardNumber: "", expireDate: "" });
  const [price] = useState(priceState || localStorage.getItem("price"));

  const cardMask = "0000 0000 0000 0000";
  const expireDateMask = "00/00";
  const Mask = [
    {
      mask: cardMask,
    },
  ];
  const ExpireMask = [
    {
      mask: expireDateMask,
    },
  ];

  const handleChange = (e) => {
    setCardData({ ...cardData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(paymePaymentData(cardData));
    navigate(`/paymeOtp`);
    setCardData({
      cardNumber: "",
      expireDate: "",
    });
  };

  return (
    <div className="paymePage">
      <div className="container">
        <div className="paymeContainer">
          <PagesIntro
            introIcon="icon-Notebook"
            introTitle="To'lov"
            introText="Eng yaxshi sarmoya - bu o'z bilimingizga investitsiya! Ishonchingiz uchun rahmat!"
          />
          <div className="paymeBox">
            <div className="paymeItems">
              <div className="paymeLogo">
                <img src={PaymeImage} alt="PaymeImage" className="img" />
              </div>
              <div className="totalAmount">
                <div className="amountText">To'lov uchun:</div>
                <div className="amount">{price}</div>
              </div>
            </div>
            <form className="paymeForm" onSubmit={handleSubmit}>
              <div className="formTitle">
                <h3 className="title">Ro'yxatdan holda to'lov</h3>
              </div>
              <div className="formBox">
                <div className="inputBox">
                  <label className="label" htmlFor="nomer">
                    Karta tafsilotlari
                  </label>
                  <IMaskInput
                    name="cardNumber"
                    mask={Mask}
                    className="input"
                    id="nomer"
                    placeholder="1234 1234 1234 1234"
                    value={cardData.cardNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="inputBox">
                  <label className="label" htmlFor="Muddat">
                    Yaroqlilik muddati
                  </label>
                  <IMaskInput
                    name="expireDate"
                    mask={ExpireMask}
                    className="input"
                    id="Muddat"
                    placeholder="MM/YY"
                    value={cardData.expireDate}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <Button>Sotib olish</Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymePage;
