import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import instance from "../api/instance";

export const bookPurchaseData = createAsyncThunk(
  "bookPurchase/bookPurchaseData",
  async (purchaseBookId) => {
    try {
      const response = await instance
        .post(
          "/item/buy",
          {
            itemId: purchaseBookId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            return res;
          } else {
            throw new Error("sotib olinmagan");
          }
        });
      toast.success("Yangi kitob sotib olganingiz bilan tabriklaymiz!");
      return response.data;
    } catch (error) {
      toast.error(`Bu kitob allaqachon sizning qo'lingizda`);
    }
  }
);

const bookPurchaseSlice = createSlice({
  name: "bookPurchase",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookPurchaseData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bookPurchaseData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(bookPurchaseData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default bookPurchaseSlice.reducer;
