import React from "react";
import PagesIntro from "../../components/UI/PagesIntro";
import ContactsForm from "../../components/UI/ContactsForm";
import contactsMap from "../../assets/images/map.png";
import "./styles.sass";
import { Link } from "react-router-dom";

const ContactsPage = () => {
  return (
    <div className='contactsPage'>
      <div className='container'>
        <div className='contactsPageContainer'>
          <PagesIntro
            introIcon='icon-Play'
            introTitle='Kontaktlar'
            introText="Agar sizda biron bir savol bo'lsa yoki Proskill Academy haqida ko'proq bilmoqchi bo'lsangiz, so'rov qoldiring va biz sizga qayta qo'ng'iroq qilamiz."
          ></PagesIntro>
          <div className='contactsPageItems'>
            <div className='contactItem'>
              <ContactsForm />
            </div>
            <div className='contactItem'>
              <div className='itemImage'>
                <img
                  src={contactsMap}
                  alt='contactsMap'
                  className='img'
                />
              </div>
              <div className='contactsMessangers'>
                <div className='messangersTitle'>
                  <div className='title'>Biz bilan bog'lanish:</div>
                </div>
                <div className='messangers'>
                  <Link
                    className='icon'
                    to='https://t.me/proskill_uz'
                  >
                    <i className='icon-telegram'></i>
                  </Link>
                  <Link
                    className='icon'
                    to='https://www.instagram.com/proskill_uz?igsh=YTN4YXcyanQ3Njlo'
                  >
                    <i className='icon-instagram'></i>
                  </Link>
                  <Link className='icon'>
                    <i className='icon-facebook'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
