import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HandleTrailingSlash = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location, navigate]);

  return null;
};

export default HandleTrailingSlash;
