import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { authApi, loginApi, phoneCodeApi, registerApi } from "../api/auth";

export const registrationForm = createAsyncThunk(
  "user/register",
  async (values, { rejectWithValue }) => {
    try {
      const response = await registerApi(values);
      if (response.status === 409 || response.status === 400) {
        toast.error("Bu telefon raqamiga ega foydalanuvchi allaqachon roʻyxatdan oʻtgan");
        return rejectWithValue(await response.json());
      }
      Cookies.set("phoneNumber", values.phoneNumber.replace(/[^\d+]/g, ""));
      Cookies.set("fullName", values.fullName);
      Cookies.set("email", values.email);
      Cookies.set("password", values.password);
      const data = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendOtp = createAsyncThunk("user/otp", async (code) => {
  try {
    const response = await authApi(code);
    toast.success("Siz roʻyxatdan oʻtishni muvaffaqiyatli yakunladingiz!");
    return response.data;
  } catch (error) {
    toast.error("Nimadir xato ketdi");
    throw new Error(error);
  }
});
export const sendPhoneCode = createAsyncThunk("otpRepeat/sendOtp", async () => {
  try {
    const response = await phoneCodeApi();
    toast.success(
      `Kod raqamga qayta yuborildi: ${Cookies.get("phoneNumber")} `
    );
    return response.data;
  } catch (error) {
    toast.error("Nimadir xato ketdi");
    throw new Error(error);
  }
});

export const loginForm = createAsyncThunk("user/login", async (values) => {
  try {
    const response = await loginApi(values);
    toast.success("Siz o'z hisobingizga muvaffaqiyatli kirdingiz");
    return response.data;
  } catch (error) {
    toast.error("Nimadir xato ketdi");
    throw new Error(error);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    auth: [],
    isAuthenticated: false,
    token: Cookies.get("access_token") || null,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(registrationForm.pending, (state) => {
      state.loading = true;
      state.isAuthenticated = false;
      state.error = "";
    });
    builder.addCase(registrationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = null;
    });
    builder.addCase(registrationForm.rejected, (state, action) => {
      state.error = action.payload;
      state.isAuthenticated = false;
      state.loading = false;
    });

    builder.addCase(sendPhoneCode.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(sendPhoneCode.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sendPhoneCode.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(sendOtp.pending, (state) => {
      state.isAuthenticated = false;
      state.error = null;
    });
    builder.addCase(sendOtp.fulfilled, (state) => {
      state.isAuthenticated = true;
      state.error = null;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.error = action.error.message || "OTP yuborilmadi";
    });

    builder.addCase(loginForm.pending, (state) => {
      state.loading = true;
      state.isAuthenticated = false;
      state.error = "";
    });
    builder.addCase(loginForm.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = null;
    });
    builder.addCase(loginForm.rejected, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.error.message;
    });
  },
});

export default authSlice.reducer;
