import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.sass";
import Cookies from "js-cookie";

const TopBanner = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/auth" || location.pathname === "/otp" ? (
        <></>
      ) : (
        <div className='container'>
          <div className='bannerInner'>
            {Cookies.get("access_token") ? (
              <Link
                className='topLink'
                to='/#price'
              >
                Kursga oldindan buyurtma berish
                <i className='star icon-star-full' /> Yangi foydalanuvchilar uchun 50% chegirma <i className='icon-arrow-right2' />
              </Link>
            ) : (
              <Link
                className='topLink'
                to='/#price'
              >
                Kursga oldindan buyurtma berish
                <i className='star icon-star-full' /> Yangi foydalanuvchilar uchun 50% chegirma <i className='icon-arrow-right2' />
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TopBanner;
