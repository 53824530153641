import React from "react";
import { useNavigate } from "react-router-dom";

import s from "./index.module.scss";

const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  const { id, title, description, _count, author, time } = course;

  const handleViewCourse = () => {
    navigate(`/courses/${id}`);
  };

  return (
    <div className={s.card}>
      {course.price === 0 && (
        <div className={s.card_blured}>
          <h3>Sotib olingan</h3>
        </div>
      )}
      <div className={s.card_content}>
        <div className={s.left}>
          <h4>Kurs</h4>
          <h3>{title}</h3>
          <p>Modullar: {_count.modules}</p>
        </div>
        <div className={s.right}>
          <p className={s.author}>{author}</p>
          <p>{description}</p>
          <p className={s.time}>Vaqt: {time}</p>
          <button
            disabled={course.price === 0}
            onClick={handleViewCourse}
            className={s.view}
          >
            Kursni ko'rish
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
