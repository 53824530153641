import React from "react";
import "./styles.sass";
import TestCard from "../../components/UI/TestCard";
import PagesIntro from "../../components/UI/PagesIntro";
import { Link } from "react-router-dom";
import payme from "../../assets/images/payme.png";
import click from "../../assets/images/Click.png";
import Button from "../../components/UI/Button";

const PaymentPage = () => {
  return (
    <div className='paymnerPage'>
      <div className='container'>
        <div className='paymentPageContainer'>
          <PagesIntro
            introIcon='icon-Notebook'
            introTitle="To'lov"
            introText="Eng yaxshi sarmoya - bu o'z bilimingizga investitsiya!
 Ishonchingiz uchun rahmat!"
          ></PagesIntro>
          <div className='paymentBox'>
            <div className='paymentText'>
              <div className='text'>
                <strong>Eng yaxshi investitsiyalar- </strong>
                bu sizning bilimingizga sarmoyadir. Ta'lim va shaxsiy rivojlanish ko'plab imkoniyatlar eshigini ochadi,
                dunyodagi o'zgarishlarga yaxshiroq moslashish va hayot sifatini yaxshilash imkonini beradi. Bilim - bu
                kapital yo'qotib bo'lmaydigan. Ishonchingiz uchun rahmat!
              </div>
            </div>
            <div className='payment'>
              <div className='paymentTitle'>
                <h4 className='title'>To'lov usulini tanlang:</h4>
              </div>
              <div className='paymentBtns'>
                <Link
                  to='/payme'
                  className='links'
                >
                  <img
                    src={payme}
                    alt='payme'
                    className='img'
                  />
                </Link>
                <Link
                  to='/click'
                  className='links'
                >
                  <img
                    src={click}
                    alt='click'
                    className='img'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
