import React from "react";
import PagesIntro from "../../components/UI/PagesIntro";
import AboutCard from "../../components/UI/AboutCard";
import cardAboutInfo1 from "../../assets/images/about3.jpg";
import cardAboutInfo2 from "../../assets/images/about5.jpg";
import cardAboutInfo3 from "../../assets/images/about6.jpg";
import Achivements from "../../components/Achievements";
import sampleVideo from "../../assets/ourApproach.mp4";
import "./styles.sass";

const AboutPage = () => {
  return (
    <div className='aboutPage'>
      <div className='container'>
        <PagesIntro
          introIcon='icon-notebookBookmark'
          introTitle='Biz haqimizda'
          introBg='pageAbout'
          introText='Biz har doim platformamizni takomillashtirish bo‘yicha Sizining takliflaringizga ochiqmiz. Bizning vazifamiz o‘quv jarayonini qulay va qiziqarli qilishdir.'
        ></PagesIntro>
        <div className='aboutPageContainer'>
          <AboutCard
            title='Proskill Academy Kompaniyasi'
            text='Biz zamonaviy va izlanuvchan kasblarga kirishni ta’minlaydigan zamonaviy ta’lim platformasimiz. Kompaniyamizning asosiy afzalligi moslashuvchanlik va qulaylikdir: istalgan vaqtda va joyda o‘qish mumkin, bu zamonaviy ish sharoitida ayniqsa muhimdir.'
            image={cardAboutInfo1}
          />
          <video
            controls
            autoPlay
            loop
            muted
            className='videoAbout'
          >
            <source
              src={sampleVideo}
              type='video/mp4'
            />
          </video>
          <Achivements />
          <AboutCard
            title='Bizning vazifamiz'
            text='Bu yutuqlar doimiy ravishda takomillashtirish va mijozlarimizning ehtiyojlarini qondirishga intilgan professionallar jamoamizning yaxshi muvofiqlashtirilgan mehnati natijasidir. Biz erishgan yutuqlarimiz bilan faxrlanamiz va bor e’tiborimizni yanada rivojlantirish va muvaffaqiyatga qaratamiz.'
            image={cardAboutInfo2}
            className='infoReverse'
          />
          {/* <AboutCard
            title='Bizning vazifamiz'
            text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua. Ut enum ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua. Ut enum ad minim veniam, quis nostrud'
            image={cardAboutInfo3}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
