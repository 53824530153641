import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "./styles.sass";

const ReviewsSlider = () => {
  const reviewsItems = [
    {
      id: 1,
      message:
        "Kurs mening barcha taxminlarimdan oshdi! Material aniq va tushunarli tarzda taqdim etildi va amaliy vazifalar bilimlarni mustahkamlashga yordam berdi. ProSkill o‘qituvchilariga katta rahmat!",
      name: "Anna K.",
      from: "O‘zbekiston, Samarqand",
    },
    {
      id: 2,
      message:
        " ProSkill -bu mashg‘ulotlar nuqtayi nazaridan men bilan sodir bo‘lgan eng yaxshi narsa. Moslashuvchan jadval bizga ish va o‘qishni birlashtirishga imkon berdi va kuratorlarning ko‘magi faqat yuqori bo‘ldi!",
      name: " Kamron M.",
      from: "O‘zbekiston Jizzax,",
    },
    {
      id: 3,
      message:
        "Kurs nihoyatda foydali va ilhomlantiruvchi edi. Men nafaqat bilimga, balki o‘z ishimda allaqachon foydalanadigan amaliy ko‘nikmalarga ham ega bo‘ldim. Ajoyib trening uchun ProSkill ga rahmat!",
      name: "Tahmina J",
      from: "O‘zbekiston, Nukus",
    },
    {
      id: 4,
      message:
        "Savdo menejeri” kursini o‘tdim va endi o‘z bilimlarimni ishda ishonchli qo‘llayapman. O‘qituvchilar o‘z ishlarini biladigan haqiqiy mutaxassislardir. ProSkill ga rahmat!",
      name: "  Aisha S.",
      from: "Turkiya, Anqara",
    },
    {
      id: 5,
      message:
        "Ajoyib kurs! Interaktiv vazifalar va hayotdagi keyslar o‘qishni qiziqarli va samarali qildi. Endi men o‘z kasbimda ancha ishonch bilan xis qilayapman",
      name: "Elena T.",
      from: "O‘zbekston, Termez",
    },
    {
      id: 6,
      message:
        "ProSkill menga mahoratimni sezilarli darajada oshirishga yordam berdi. Nazariya va amaliyot mukammal darajada muvozanatli va o‘quv materiallari har doim dolzarb va foydalidir. Men tavsiya etaman!",
      name: "Temur G.",
      from: "O‘zbekiston, Chirchiq",
    },
    {
      id: 7,
      message:
        " ProSkill da o‘qish kelajakka qadamdir. O‘qituvchilar har doim yordam berishga tayyor va kurs materiallari oson hazm qilinadi. O‘qishdan so‘ng meni muvaffaqiyatli ishga joylashtirishdi!",
      name: " Olga V.",
      from: "Rossiya, Orenburg ",
    },
    {
      id: 8,
      message:
        " ProSkill kursi menga nafaqat bilim, balki keyingi rivojlanish uchun motivatsiya ham berdi. Men yangi professional chaqqiruvlarga tayyorman!",
      name: " Dmitriy P.",
      from: "Rossiya, Moskva",
    },
    {
      id: 9,
      message:
        "Ajoyib o‘quv platformasi! Qulay interfeys, materiallarga 24/7 kirish imkoniyati va ajoyib o‘qituvchilar. MArketing bo‘yicha kursi men uchun yangi ufqlarni ochdi.",
      name: " Nilufar L.",
      from: "O‘zbekiston Tashkent",
    },
    {
      id: 10,
      message:
        "Men juda ko‘p kurslarni olganman, ammo ProSkill eng yaxshisi. Hammasi yuqori darajada tashkil etilgan: materiallar taqdimotidan qarshi aloqagacha. Butun jamoaga katta rahmat!",
      name: "Vladimir N.",
      from: "O‘zbekiston, Tashkent",
    },
  ];
  return (
    <>
      <Swiper
        spaceBetween={50}
        loop={true}
        autoplay={{ delay: 100 }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        speed={3200}
        className='reviewsSlider'
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          420: {
            slidesPerView: 1,
          },
          577: {
            slidesPerView: 1,
          },
          865: {
            slidesPerView: 2,
          },
          911: {
            slidesPerView: 3,
          },
        }}
      >
        {reviewsItems.map((item, index) => (
          <SwiperSlide key={index}>
            <div className='sliderContainer'>
              <div className='sliderMessage'>
                <div className='messageText'>
                  <p className='text'>{item.message}</p>
                </div>
              </div>
              <div className='sliderBottomItems'>
                <div className='itemInfo'>
                  <div className='infoTitle'>
                    <h4 className='title'>{item.name}</h4>
                  </div>
                  <div className='infoSubtitle'>
                    <p className='subTitle'>{item.from}</p>
                  </div>
                </div>
                <div className='itemStars'>
                  <div className='star'>
                    <i className='icon-star-full'></i>
                  </div>
                  <div className='star'>
                    <i className='icon-star-full'></i>
                  </div>
                  <div className='star'>
                    <i className='icon-star-full'></i>
                  </div>
                  <div className='star'>
                    <i className='icon-star-full'></i>
                  </div>
                  <div className='star'>
                    <i className='icon-star-full'></i>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ReviewsSlider;
