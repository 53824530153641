import React from "react";

import s from "./index.module.scss";

import { formatPrice } from "../../../utils";

const Lesson = ({ lesson, selected, onLessonChange }) => {
  return (
    <div className={s.card}>
      {lesson.price === 0 && (
        <div className={s.card_blured}>
          <h3>Sotib olingan</h3>
        </div>
      )}
      <div className={s.card_content}>
        <div className={s.content}>
          <h4>{lesson.title}</h4>
          <p>Vaqt {lesson.time}</p>
        </div>
        <div className={s.control}>
          <p>{formatPrice(lesson.price)} so'm</p>
          <input
            type='checkbox'
            disabled={lesson.price === 0}
            checked={lesson.price === 0 || selected}
            onChange={onLessonChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Lesson;
