import React from "react";
import "./styles.sass";

const Timeline = () => {
  return (
    <section
      className='timeline'
      id='learning'
    >
      <div className='container'>
        <div className='sectionTitleBlack'>
          <h3 className='title'>O‘quv jarayoni</h3>
        </div>
        <div className='timelineItems'>
          <div className='timelineItem'>
            <div className='timelineDot'></div>
            <div className='timelineTitle'>
              <h4 className='title'>Sinov (Test)</h4>
            </div>
            <div className='timelineText'>
              <p className='text'>Siz sinovdan o‘tasiz, va biz sizga qancha modul o‘tishingizni aniqlaymiz.</p>
            </div>
          </div>
          <div className='timelineItem'>
            <div className='timelineDot'></div>
            <div className='timelineTitle'>
              <h4 className='title'>Kursni rasmiylashtirish</h4>
            </div>
            <div className='timelineText'>
              <p className='text'>
                Sizga kerak bo‘lgan tarifni tanlang, to‘lov amalga oshiriladi va siz bizning hamjamiyatimizning bir
                qismiga aylanasiz.
              </p>
            </div>
          </div>

          <div className='timelineItem'>
            <div className='timelineDot'></div>
            <div className='timelineTitle'>
              <h4 className='title'>O‘qish</h4>
            </div>
            <div className='timelineText'>
              <p className='text'>
                Siz uchun qulay vaqtda murabbiylarimiz sizga yordam beradigan materiallarni o‘rganishni boshlaysiz.
              </p>
            </div>
          </div>
          <div className='timelineItem'>
            <div className='timelineDot'></div>
            <div className='timelineTitle'>
              <h4 className='title'>Taklif</h4>
            </div>
            <div className='timelineText'>
              <p className='text'>
                Siz kurslarni muvaffaqiyatli yakunlaysiz va sizga sherik kompaniyada ish topish imkoniyati beriladi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
