import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import s from "./index.module.scss";

import { formatPrice } from "../../../utils";

import { setPurchasePrice, setPurchaseProducts } from "../../../store/courses";
import { selectCourse } from "../../../store/selectors/courses";

const Panel = ({ selectedModules, selectedLessons }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: course } = useSelector(selectCourse);

  const [independentLessons, setIndependentLessons] = useState([]);

  const lessons = Object.fromEntries(
    Object.entries(selectedLessons).filter(([__, value]) => value === true)
  );
  const modules = Object.fromEntries(
    Object.entries(selectedModules).filter(([__, value]) => value === true)
  );

  const independentModules = Object.fromEntries(
    Object.entries(selectedModules).filter(
      ([key, value]) =>
        value === true &&
        course.modules.find((module) => module.id == key)?.price !== 0
    )
  );

  const flatLessons = course.modules.flatMap((module) => module.lessons);

  useEffect(() => {
    setIndependentLessons([]);
    course.modules.forEach((module) => {
      if (!modules[module.id]) {
        module.lessons.forEach((lesson) => {
          if (lessons[lesson.id] && lesson.price !== 0) {
            setIndependentLessons((lessons) => [...lessons, lesson]);
          }
        });
      }
    });
  }, [selectedLessons]);

  const calcPrice = () => {
    let price = 0;

    if (!(Object.keys(modules).length === course.modules.length)) {
      Object.keys(modules).forEach((moduleId) => {
        const foundModule = course.modules.find((mod) => mod.id === +moduleId);
        price += foundModule?.price;
      });

      price += independentLessons.reduce(
        (sum, lesson) => sum + lesson.price,
        0
      );
    } else price = course.price;

    return formatPrice(price);
  };

  const handleBuy = () => {
    if (Cookies.get("access_token")) {
      const totalPrice = calcPrice();
      let products;
      if (!(Object.keys(modules).length === course.modules.length)) {
        products = [
          ...independentLessons.map((lesson) => ({
            productType: "lesson",
            productId: lesson.id,
          })),
          ...Object.keys(independentModules).map((id) => ({
            productType: "module",
            productId: Number(id),
          })),
        ];
      } else
        products = [
          {
            productType: "course",
            productId: course.id,
          },
        ];

      dispatch(setPurchasePrice(totalPrice));
      localStorage.setItem("price", totalPrice);
      dispatch(setPurchaseProducts(products));
      navigate("/payment");
    } else {
      navigate("/auth");
    }
  };

  if (+calcPrice() === 0) return null;
  return (
    <div className={s.panel}>
      <div className={s.content}>
        <p>Tanlangan:</p>
        {Object.keys(modules).length === course.modules.length ? (
          <>
            <b>Xo'sh:</b>
            <p>{course.title}</p>
          </>
        ) : (
          <>
            {!!Object.keys(independentModules).length && (
              <>
                <b>Modullar:</b>
                <ul>
                  {Object.keys(independentModules).map((moduleId) => (
                    <li key={moduleId}>
                      {
                        course.modules.find((mod) => mod.id === +moduleId)
                          ?.title
                      }
                    </li>
                  ))}
                </ul>
              </>
            )}

            {!!independentLessons.length && (
              <>
                <b>Individual darslar:</b>
                <ul>
                  {independentLessons.map((lesson) => (
                    <li key={lesson.id}>
                      {
                        flatLessons.find(
                          (flatLesson) => flatLesson.id === +lesson.id
                        )?.title
                      }
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
      <div className={s.priceBlock}>
        <div>Yakuniy narx: {calcPrice()} so'm</div>
        <button onClick={handleBuy}>Sotib olish</button>
      </div>
    </div>
  );
};

export default Panel;
