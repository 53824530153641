import React from "react";
import "./styles.sass";
import PagesIntro from "../../components/UI/PagesIntro";
import Quote from "../../components/UI/Quote";
import CardNews from "../../components/UI/CardNews";
import NewsSlider from "../../components/NewsSlider";
import image1 from "../../assets/images/imageNews1.jpg";
import image2 from "../../assets/images/imageNews2.jpg";
import image3 from "../../assets/images/imageNews3.jpg";
import image4 from "../../assets/images/imageNews5.png";
import image5 from "../../assets/images/imageNews6.jpg";
import image6 from "../../assets/images/imageNews24.jpg";

import image7 from "../../assets/images/news3.jpg";
import image8 from "../../assets/images/news4.png";
import image9 from "../../assets/images/news5.jpg";
import image10 from "../../assets/images/news6.jpg";
import image11 from "../../assets/images/news7.jpeg";
import image12 from "../../assets/images/news8.jpg";

const NewsPage = () => {
  return (
    <div className='newsPage'>
      <div className='container'>
        <div className='pageNewsContainer'>
          <PagesIntro
            introIcon='icon-Notebook'
            introTitle='Yangiliklar'
            introBg='pageNews'
            introText='Yangiliklarni kuzatib borish, axborot kun tartibida bo‘lish va orqada qolmaslik muhim bo‘lib, shunda siz doimo talabda va dolzarb bo‘lasiz.'
          >
            <Quote
              name='Endi Uorxol'
              text='"Biznesda muvaffaqiyat qozonish eng qiziqarli san’atdir. Pul qilish - bu san’at, ishlash - bu san’at, yaxshi biznes esa - eng yaxshi san’atdir".'
            />
          </PagesIntro>
          <div className='cardsNewsContainer'>
            <div className='cards'>
              <CardNews
                imageNews={image1}
                title="Sotish va marketing sohasidagi iste'mol tovarlari bozori tendentsiyalari"
                content="Joriy yilda isteʼmol tovarlari sohasida boʻsh ish oʻrinlari 2022-yilga nisbatan oʻrtacha 25–30 foizga oshganini koʻrmoqdamiz. Ko'pgina mijozlarimiz savdo, marketing va e-com sohasida faol qidiruv va xodimlarni tanlashni boshladilar."
                link='https://ancor.ru/press/insights/trendy-rynka-tnp-v-sfere-prodazh-i-marketinga/'
              />
              <CardNews
                imageNews={image2}
                title="Qanday qilib e'tiborni jalb qilish va mijozni saqlab qolish - 2024 yil uchun savdo hunisi strategiyalari"
                content='Mijozlarni bir necha bosqichda jalb qilish orqali'
                link='https://secrets.tinkoff.ru/blogi-kompanij/instrumenty-privlecheniya-klientov/'
              />
            </div>
            <div className='cards'>
              <CardNews
                imageNews={image3}
                title='Mijoz tajribasi'
                content="Mijozning brend bilan o'zaro munosabatidan olgan umumiy taassurot va qoniqish"
                link='https://secrets.tinkoff.ru/glossarij/klientskij-opyt/'
              />
              <CardNews
                imageNews={image4}
                title='Savdoning asosiy bosqichlari: texnika va bosqichlar ketma-ketligi'
                content="Umumiy ibora bor: 'U hamma narsani sotishi mumkin: hatto cho'lda qum, hatto Antarktidadagi qor ham.' Bu kimdir tabiiy muzokarachi, boshqalari esa oddiy emasligini anglatadimi?"
                link='https://sendpulse.com/ru/blog/stages-of-the-sales-cycle'
              />
            </div>
            <div className='cards'>
              <CardNews
                imageNews={image5}
                title="Qo'rg'oshin"
                content="Biznes mahsulotiga qiziqqan va aloqa ma'lumotlarini qoldiradigan shaxs"
                link='https://secrets.tinkoff.ru/glossarij/lid/'
              />
              <CardNews
                imageNews={image6}
                title="Savdo psixologiyasi: xaridorlarga ta'sir qilishning 25 ta samarali usullari"
                content="Savdo psixologiyasi - bu hech qanday zamonaviy biznessiz qila olmaydigan narsa. Har kuni biz biror narsa sotib olamiz, ya'ni sotuvchilar bizni qandaydir tarzda jalb qilishlari kerak, shunda biz raqobatchilarga bormaymiz va boshqa birovning mahsuloti yoki xizmatini tanlamaymiz."
                link='https://sales-generator.ru/blog/psikhologiya-prodazh/'
              />
            </div>
            <div className='cards'>
              <CardNews
                imageNews={image7}
                title="Sotuvchilar taqiqlashi kerak bo'lgan iboralar"
                content="Biz nima haqida gapiryapmiz? Savdo skriptlari ajoyib. Ammo bu vaziyat nazoratdan chiqib ketganda va menejer improvizatsiya qilishga majbur bo'lganda sodir bo'ladi. Bunday holda, asosiy narsa chiziqni kesib o'tmaslikdir."
                link='https://sales-generator.ru/blog/frazy-kotorye-nuzhno-zapretit-prodavtsam/'
              />
              <CardNews
                imageNews={image8}
                title='Mijozlar bilan aloqa: usullar, tamoyillar, vositalar'
                content="Mijozlar bilan muloqot ularning qoniqishiga, sodiqligiga va takroriy biznes soniga ta'sir qiladi. Bundan tashqari, qoniqarli mijoz o'z tajribasi haqida boshqalarga aytib beradigan bepul reklama manbaidir."
                link='https://sales-generator.ru/blog/kommunikatsiya-s-klientami/'
              />
            </div>

            <div className='cards'>
              <CardNews
                imageNews={image9}
                title="Issiq qo'ng'iroqlar: sotish uchun oxirgi qadam"
                content="Issiq qo'ng'iroqlar, sovuq qo'ng'iroqlardan farqli o'laroq, kompaniya haqida ma'lumot to'plash, qaror qabul qiluvchiga murojaat qilish yoki tijorat taklifini yuborishga qaratilgan emas. Maqsad - mahsulot yoki xizmatni sotish. Ya'ni, mijoz allaqachon xarid qilishga tayyor, qolgani uni bu qadamga biroz surishdir."
                link='https://sales-generator.ru/blog/goryachie-zvonki/'
              />
              <CardNews
                imageNews={image10}
                title="Raqobatchilarning mijozlari: qanday qilib to'g'ri ishlash kerak"
                content="Raqobatchilarning mijozlari sizning potentsial iste'molchilaringizdir. Tahlil o'tkazish va nima uchun ular sizdan mahsulotni sotib olmasligini aniqlash orqali siz aniqroq taklif yaratishingiz va bozorda xaridorlarning muhim qismini olishingiz mumkin."
                link='https://sales-generator.ru/blog/klienty-konkurentov/'
              />
            </div>

            <div className='cards'>
              <CardNews
                imageNews={image11}
                title='Mijoz ehtiyojlari: ularni aniqlash va shakllantirishning oddiy usullari'
                content="Kompaniyaning foydasi bevosita mijozlar bilan tuzilgan bitimlar soniga bog'liq. Savdoni oshirishning turli usullari mavjud: ma'lum reklama kanallaridan foydalanish, chegirmalar taklif qilish, aktsiyalarni o'tkazish va hokazo."
                link='https://sales-generator.ru/blog/potrebnosti-klienta/'
              />
              <CardNews
                imageNews={image12}
                title='Mahsulotni sotmasdan qanday sotish mumkin: texnikalar, usullar, maslahatlar'
                content='Mijoz menejerlar tomonidan ovlanishini istamaydi, faol ravishda biror narsaga itarishni xohlamaydi, unga sotiladi, lekin sotib olishni xohlaydi. Bu muammo biznesning deyarli barcha sohalariga taalluqlidir, xoh u tovarlar, xizmatlar, B2B yoki B2C savdosi.'
                link='https://sales-generator.ru/blog/kak-prodavat-tovar-ne-prodavaya/'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='newsSliderContainer'>
        <NewsSlider />
      </div>
    </div>
  );
};

export default NewsPage;
