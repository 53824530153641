import React, { useEffect } from "react";

import CourseCard from "../../components/Courses/CourseCard/CourseCard";

import s from "./index.module.scss";

import { getCourses } from "../../store/courses";
import { useDispatch, useSelector } from "react-redux";
import { selectCourses } from "../../store/selectors/courses";

const Courses = () => {
  const dispatch = useDispatch();

  const { list: courses, loading } = useSelector(selectCourses);

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  if (loading) return <div>Yuklanmoqda...</div>;
  return (
    <div className={s.list}>
      {courses.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
        />
      ))}
    </div>
  );
};

export default Courses;
