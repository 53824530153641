import React from "react";
import Button from "../Button";
import "./styles.sass";
import { Link } from "react-router-dom";

const ModalToAdmin = ({ cancel, bookData }) => {
  return (
    <div className='modalBox'>
      <div className='container'>
        <div className='modalBoxContainer'>
          <div className='modalBoxTitle'>
            <h3 className='title'>
              Xaridingiz bilan tabriklaymiz: xaridingizni hisob qaydnomangizda ko'rishingiz mumkin
            </h3>
          </div>

          <div className='btnsGroup'>
            <Button
              className='error'
              onClick={cancel}
            >
              Yo'q
            </Button>
            <Link to='http://proskill-academy.net/'>
              <Button>Shaxsiy hisob</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalToAdmin;
