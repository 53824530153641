import React, { useState } from "react";
import Button from "../UI/Button";
import BenefitsCard from "./BenefitsCard";
import "./styles.sass";
import TextModalBox from "../UI/TextModalBox";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Benefits = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState(null);

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });
  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section
      className='benefits'
      id='benefits'
    >
      <div className='blob first'></div>

      <div className='blob second'></div>

      <div className='blob third'></div>

      <div className='blob forth'></div>

      <div className='container'>
        <div className='benefitsContainer'>
          <div className='benefitsIformation'>
            <div className='benefitsHeading'>
              <h2 className='heading'>Biz bilan ishlashning afzalliklari</h2>
            </div>
            <div className='benefitsText'>
              <p className='text'>
                Proskill Academy da o‘qish tegishli bilim va amaliy ko‘nikmalarni beradi, kurslarni muvaffaqiyatli
                tugatgandan so‘ng ishga joylashadi. O‘qituvchilar haqiqiy tajriba va insaytlar bilan bo‘lishadilar.
                Moslashuvchan (egiluvchan) jadval sizga qulay vaqtda o‘qish imkonini beradi, bu esa jarayonni iloji
                boricha samarali va qulay qiladi.
              </p>
            </div>
            <div className='btnContainer'>
              {Cookies.get("access_token") ? (
                <Link to='/#price'>
                  <Button className='outline'> Kursni sotib olish</Button>
                </Link>
              ) : (
                <Link to='/auth'>
                  <Button className='outline'> Kursni sotib olish</Button>
                </Link>
              )}
              <Button
                className='outline'
                onClick={openModal}
              >
                Sinovdan o‘tish
              </Button>
              {isModalOpen && <TextModalBox onClick={closeModal} />}
            </div>
          </div>
          <div className='blob first'></div>

          <div className='blob second'></div>
          {windowWidth > 850 ? (
            <div className='benefitsCards'>
              <div className='cardsBox'>
                <BenefitsCard
                  className='benefitsCard'
                  icon='icon-pen'
                  title='O‘qituvchilar. '
                  textFront='O‘z sohalarida faqat eng zo‘rlari!'
                  textBack='
                O‘qituvchilarimiz ko‘p yillik ish tajribasi va yaxshi kasbiy mahoratga egadirlar.'
                />
              </div>
              <div className='cardsBox'>
                <BenefitsCard
                  className='benefitsCard'
                  icon='icon-medal'
                  title='Bilim'
                  textFront='Istalgan vaqtda resurslarga kirish.'
                  textBack='
                Savdo, marketing va psixologiya bo‘yicha 100 dan ortiq kitoblarga ega onlayn kutubxona. Biz butun internetdan videokliplar, yangiliklar va maqolalarni to‘pladik.'
                />
                <BenefitsCard
                  className='benefitsCard'
                  icon='icon-shield'
                  title='Ish bilan ta’minlash. '
                  textFront='Siz o‘zingizni yangi kasbda sinab ko‘rishingiz mumkin.'
                  textBack='
                Kursimizni muvaffaqiyatli tugatgandan so‘ng, biz ish bilan ta’minlashni kafolatlaymiz va bu yerda siz o‘z bilimlaringizni amaliyot bilan mustahkamlashingiz mumkin.'
                />
              </div>
            </div>
          ) : (
            <div className='benefitsCards'>
              <div className='cardsBox'>
                <BenefitsCard
                  className={`benefitsCardMobile ${activeIndex == 0 ? "active" : ""}`}
                  onClick={() => handleClick(0)}
                  icon='icon-pen'
                  title='O‘qituvchilar. '
                  textFront='O‘z sohalarida faqat eng zo‘rlari!'
                  textBack='
                O‘qituvchilarimiz ko‘p yillik ish tajribasi va yaxshi kasbiy mahoratga egadirlar.'
                />
              </div>
              <div className='cardsBox'>
                <BenefitsCard
                  className={`benefitsCardMobile ${activeIndex == 1 ? "active" : ""}`}
                  onClick={() => handleClick(1)}
                  icon='icon-medal'
                  title='Bilim'
                  textFront='Istalgan vaqtda resurslarga kirish.'
                  textBack='
                Savdo, marketing va psixologiya bo‘yicha 100 dan ortiq kitoblarga ega onlayn kutubxona. Biz butun internetdan videokliplar, yangiliklar va maqolalarni to‘pladik.'
                />
                <BenefitsCard
                  className={`benefitsCardMobile ${activeIndex == 2 ? "active" : ""}`}
                  onClick={() => handleClick(2)}
                  icon='icon-shield'
                  title='Ish bilan ta’minlash. '
                  textFront='Siz o‘zingizni yangi kasbda sinab ko‘rishingiz mumkin.'
                  textBack='
                Kursimizni muvaffaqiyatli tugatgandan so‘ng, biz ish bilan ta’minlashni kafolatlaymiz va bu yerda siz o‘z bilimlaringizni amaliyot bilan mustahkamlashingiz mumkin.'
                />
              </div>
            </div>
          )}
        </div>
        <div className='blob first'></div>

        <div className='blob second'></div>

        <div className='blob third'></div>

        <div className='blob forth'></div>
      </div>
    </section>
  );
};

export default Benefits;
