import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registrationForm } from "../../../store/authSlice";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import Cookies from "js-cookie";
import "./styles.sass";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneMask = "+998 (00) 000-00-00";
  const Mask = [
    {
      mask: phoneMask,
    },
  ];
  const [registrationData, setRegistrationData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    city: "",
    birthday: "",
    password: "",
    repeatPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    gender: false,
    city: false,
    birthday: false,
    password: false,
    repeatPassword: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const uzbekistanCities = [
    "Toshkent",
    "Samarqand",
    "Buxoro",
    "Xiva",
    "Shahrisabz",
    "Jizzax",
    "Qo'qon",
    "Andijon",
    "Farg'ona",
    "Namangan",
    "Nukus",
    "Urganch",
    "Qarshi",
    "Navoiy",
    "Termiz",
  ];

  const validatePassword = (password) => {
    const lengthValid = password.length <= 8;
    const hasNumber = /[0-9]/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return lengthValid && hasNumber && hasLetter && hasSymbol;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData({ ...registrationData, [name]: value });

    if (name === "password" || name === "repeatPassword") {
      if (!validatePassword(value)) {
        setError(
          "Parol 8 ta belgidan oshmasligi kerak va quyidagilarni o'z ichiga olishi kerak: raqamlar, harflar va belgilar",
        );
      } else {
        setError("");
      }
    }
    setFormErrors({ ...formErrors, [e.target.name]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(registrationData);
    try {
      if (!validatePassword(registrationData.password)) {
        setError(
          "Parol 8 ta belgidan oshmasligi kerak va quyidagilarni o'z ichiga olishi kerak: raqamlar, harflar va belgilar",
        );
        return;
      }
      const resultAction = await dispatch(registrationForm(registrationData));
      const data = unwrapResult(resultAction);
      navigate(`/otp?phone=${registrationData?.phoneNumber}`);
      setRegistrationData({
        fullName: "",
        email: "",
        phoneNumber: "",
        gender: "",
        city: "",
        birthday: "",
        password: "",
        repeatPassword: "",
      });
    } catch (e) {
      console.log(e);
      toast.error("Bu raqamga ega foydalanuvchi allaqachon roʻyxatdan oʻtgan");
    }
  };

  const isFormValid = () => {
    return (
      registrationData.fullName.trim() !== "" &&
      registrationData.email.trim() !== "" &&
      registrationData.phoneNumber.trim() !== "" &&
      registrationData.city.trim() !== "" &&
      registrationData.password &&
      registrationData.repeatPassword &&
      registrationData.password === registrationData.repeatPassword
    );
  };
  useEffect(() => {
    Cookies.remove("phoneNumber");
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className='formContainer'>
      <div className='formTitle'>
        <h4 className='title'>Roʻyxatdan oʻtish</h4>
      </div>
      <form
        action=''
        className='formBox'
        onSubmit={handleSubmit}
      >
        <div className='inputBox'>
          <Input
            type='text'
            name='fullName'
            className='input'
            placeholder="To'liq ism"
            value={registrationData.fullName}
            // onChange={(e) => setRegistrationData({ ...registrationData, fullName: e.target.value })}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div className='inputBox'>
          <Input
            type='text'
            name='city'
            className='input'
            placeholder='Город'
            value={registrationData.city}
            // onChange={(e) => setRegistrationData({ ...registrationData, city: e.target.value })}
            onChange={handleChange}
            required
          />
        </div> */}
        <div className='inputBox'>
          <select
            name='city'
            className='input'
            value={registrationData.city}
            onChange={handleChange}
            required
          >
            <option
              value=''
              disabled
            >
              Shaharni tanlang
            </option>
            {uzbekistanCities.map((city, index) => (
              <option
                key={index}
                value={city}
              >
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className='inputBox'>
          <Input
            type='text'
            name='email'
            className='input'
            placeholder='Pochta'
            value={registrationData.email}
            // onChange={(e) => setRegistrationData({ ...registrationData, email: e.target.value })}
            onChange={handleChange}
            required
          />
        </div>
        <div className='inputBox'>
          <IMaskInput
            name='phoneNumber'
            className='input'
            placeholder='Telefon raqami'
            value={registrationData.phoneNumber}
            // onChange={(e) => setRegistrationData({ ...registrationData, phoneNumber: e.target.value })}
            onChange={handleChange}
            mask={Mask}
            pattern='[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}'
            maxLength={19}
            required
          />
        </div>
        <div className='inputBox'>
          <Input
            type={passwordVisible ? "text" : "password"}
            placeholder='Parol'
            className='input'
            name='password'
            value={registrationData.password}
            onChange={handleChange}
            required
          />
          <span
            onClick={togglePasswordVisibility}
            className='passwordTogleIcon'
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
          {error && <div className='errorMessage'>{error}</div>}
        </div>
        <div className='inputBox'>
          <Input
            type={passwordVisible ? "text" : "password"}
            placeholder='Parolni qayta kiriting'
            className='input'
            name='repeatPassword'
            value={registrationData.repeatPassword}
            onChange={handleChange}
            required
          />
          <span
            onClick={togglePasswordVisibility}
            className='passwordTogleIcon'
          >
            {passwordVisible ? <FaEyeSlash className='icon' /> : <FaEye className='icon' />}
          </span>
          {error && <p className='errorMessage'>{error}</p>}
        </div>
        <Button
          className={!isFormValid() ? "disabled" : ""}
          disabled={!isFormValid()}
        >
          Kirish
        </Button>
      </form>
      <div className='formLink'>
        {/* <Link to='logIn' */}
        {/* <Typography>
          Akkaunt bormi? <span>Kirish</span>
        </Typography> */}
      </div>
    </div>
  );
};

export default RegistrationForm;
