import React from "react";
import Button from "../UI/Button";
import "./styles.sass";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Price = ({ price }) => {
  const handleCourseName = (name) => {
    Cookies.set("name", name);
  };

  return (
    <section
      className='price'
      id='price'
    >
      <div className='container'>
        <div className='priceCardContainer'>
          <div className={`priceCard`}>
            <div className='cardBox'>
              <div className='cardItems'>
                <div className='cardSubtitle'>
                  <p className='subtitle'>Dars</p>
                </div>
                <div className='cardTitle'>
                  <h4 className='title'>{price.lesson.price.toLocaleString('de-DE')} so'm</h4>
                </div>
                <div className='cardText'>
                  <p className='text'>Darsga quyidagilar kiradi:</p>
                </div>
                {price.lesson.includes.map((item, index) => (
                  <div
                    className='cardInfo'
                    key={index}
                  >
                    <div className='infoIcon'>
                      <i className='icon-Pointer'></i>
                    </div>
                    <div className='infoText'>
                      <p className='text'>{item.value}</p>
                    </div>
                  </div>
                ))}
              </div>
              {Cookies.get("access_token") ? (
                <Link
                  to='/courses'
                  className='cardBtn'
                >
                  <Button onClick={() => handleCourseName("Dars")}>Darsni tanlang</Button>
                </Link>
              ) : (
                <Link
                  to='/auth'
                  className='cardBtn'
                >
                  <Button>Darsni tanlang</Button>
                </Link>
              )}
            </div>
          </div>
          <div className={`priceCard primary`}>
            <div className='cardBox'>
              <div className='cardItems'>
                <div className='cardSubtitle'>
                  <p className='subtitle'>Modul</p>
                </div>
                <div className='cardTitle'>
                  <h4 className='title'>{price.module.price.toLocaleString('de-DE')} so'm</h4>
                </div>
                <div className='cardText'>
                  <p className='text'>Modulga quyidagilar kiradi:</p>
                </div>
                {price.module.includes.map((item, index) => (
                  <div
                    className='cardInfo'
                    key={index}
                  >
                    <div className='infoIcon'>
                      <i className='icon-Pointer'></i>
                    </div>
                    <div className='infoText'>
                      <p className='text'>{item.value}</p>
                    </div>
                  </div>
                ))}
              </div>
              {Cookies.get("access_token") ? (
                <Link
                  to='/courses'
                  className='cardBtn'
                >
                  <Button onClick={() => handleCourseName("Modul")}>Modul tanlang</Button>
                </Link>
              ) : (
                <Link
                  to='/auth'
                  className='cardBtn'
                >
                  <Button>Modul tanlang</Button>
                </Link>
              )}
            </div>
          </div>
          <div className={`priceCard`}>
            {/* <div className="blur">
              <h3>Tez kunlarda!</h3>
            </div> */}
            <div className='cardBox'>
              <div className='cardItems'>
                <div className='cardSubtitle'>
                  <p className='subtitle'>Kurs</p>
                </div>
                <div className='cardTitle'>
                  <h4 className='title'>{price.course.price.toLocaleString('de-DE')} so'm</h4>
                  <h4 className='smallTitle'><s>3.999.000 so'm</s></h4>
                </div>
                <div className='cardText'>
                  <p className='text'>Kursga quyidagilar kiradi:</p>
                </div>
                {price.course.includes.map((item, index) => (
                  <div
                    className='cardInfo'
                    key={index}
                  >
                    <div className='infoIcon'>
                      <i className='icon-Pointer'></i>
                    </div>
                    <div className='infoText'>
                      <p className='text'>{item.value}</p>
                    </div>
                  </div>
                ))}
              </div>
              {Cookies.get("access_token") ? (
                <Link
                  to='/payment'
                  className='cardBtn'
                >
                  <Button onClick={() => handleCourseName("Kurs")}>Kurs sotib olish</Button>
                </Link>
              ) : (
                <Link
                  to='/auth'
                  className='cardBtn'
                >
                  <Button>Kurs sotib olish</Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Price;
