import React from "react";
import firmLogo from "../../../assets/images/firmLogo.png";
import "./styles.sass";

const Rights = () => {
  return (
    <div className='rights'>
      <div className='rightsText'>
        <p className='text'>©2015-2024 barcha huquqlar Proskill Academy tomonidan himoyalangan</p>
      </div>
      <div className='rightsDevelopers'>
        <div className='rightText'>
          <p className='text'>Ishlab chiqilgan va qo‘llab quvvatlanadi: </p>
        </div>
        <div className='rightsImage'>
          <img
            src={firmLogo}
            alt='firmLogo'
          />
        </div>
      </div>
    </div>
  );
};

export default Rights;
