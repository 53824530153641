import React from "react";
import { Link } from "react-router-dom";
import "./styles.sass";
import ContactsForm from "../UI/ContactsForm";

const ContactsBlock = () => {
  return (
    <section
      className='contactsBlock'
      id='contacts'
    >
      <div className='container'>
        <div className='contactsBlockContainer'>
          <div className='content'>
            <div className='contentTitle'>
              <h2 className='title title-primary'>Shaklni to‘ldiring va bepul dars oling! </h2>
            </div>
            <div className='contentInfo'>
              <div className='contentTitle'>
                <h2 className='title'>Bizni kuzatib boring</h2>
              </div>
            </div>
            <div className='contactsMessangers'>
              <div className='messangersTitle'>
                <div className='title'>Aloqa uchun kontaktlarimiz:</div>
              </div>
              <div className='messangers'>
                <div className='messangersIcon'>
                  <Link
                    className='icon'
                    to='https://t.me/proskill_uz'
                  >
                    <i className='icon-telegram'></i>
                  </Link>
                  <div className='iconText'>
                    <p className='text'> Biz va bizni so‘nggi yangiliklarimizni telegramda kuzatib boring.</p>
                  </div>
                </div>
                <div className='messangersIcon'>
                  <Link
                    className='icon'
                    to='https://www.instagram.com/proskill_uz?igsh=YTN4YXcyanQ3Njlo'
                  >
                    <i className='icon-instagram'></i>
                  </Link>
                  <div className='iconText'>
                    <p className='text'> Obuna bo‘ling va o‘zgarishlarni kuzatib boring.</p>
                  </div>
                </div>

                {/* <Link className='icon'>
                  <i className='icon-facebook'></i>
                </Link> */}
                <div className='messangersIcon'>
                  <Link
                    to='mailto:proskillacademychat@gmail.com'
                    className='icon'
                  >
                    <i className='icon-mail'></i>
                  </Link>
                  <div className='iconText'>
                    <p className='text'>Savollar, shikoyatlar va takliflar uchun bizga yozing.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactsForm />
        </div>
      </div>
    </section>
  );
};

export default ContactsBlock;
