import React from "react";
import sampleVideo from "../../assets/ourApproach.mp4";
import "./styles.sass";

const OurApproach = () => {
  return (
    <section
      className='ourApproach'
      id='manager'
    >
      <div className='container'>
        <div className='ourApproachContainer'>
          <div className='ourApproachBox'>
            <video
              controls
              autoPlay
              loop
              muted
            >
              <source
                src={sampleVideo}
                type='video/mp4'
              />
            </video>
          </div>
          <div className='ourApproachBox'>
            <div className='boxHeading'>
              <h2 className='heading'>Savdo bo‘yicha Menejer</h2>
            </div>
            <div className='boxText'>
              <p className='text'>
                Savdo bo‘yicha menejeri treningi nazariy bilim va amaliy ko‘nikmalarning kombinatsiyasiga asoslangan.
                Biz tez o‘zgaruvchan marketing dunyosida nafaqat asosiy tushunchalarni bilish, balki ularni amalda
                qo‘llay olish ham muhimligini tushunamiz. Shuning uchun har bir kursimiz dolzarb keysilar, interaktiv
                vazifalar va real loyihalarni o‘z ichiga oladi.
              </p>
              <p className='text'>
                Bizning o‘qituvchilarimiz savdo sohasida ko‘p yillik tajribaga ega, o‘z bilimlari va tushunchalarini
                baham ko‘rishga tayyor mutaxassislardir. Biz har bir talaba savol va qayta aloqa olishi mumkin bo‘lgan
                qulay va qo‘llab-quvvatlovchi muhitni yaratamiz. O‘qishni shaxsiylashtirishga alohida e’tibor beriladi:
                talabalar kurslarni tugatish tezligini tanlashlari, shuningdek, aniq mansab maqsadlarini belgilashlari
                mumkin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurApproach;
