import React, { useEffect } from "react";
import LibraryCard from "../UI/LibraryCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchLibraryData } from "../../store/librarySlice";
import imageAudio from "../../assets/images/iconAudion.png";
import imagePdf from "../../assets/images/imagePdf.png";
import imagesBooks from "../../assets/images/imagesBooks.png";
import imagesVideo from "../../assets/images/imagesVideo.png";
import "./styles.sass";

const Library = () => {
  const dispatch = useDispatch();
  const libraryData = useSelector((state) => state.library.data);
  const status = useSelector((state) => state.library.status);
  const error = useSelector((state) => state.library.error);
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLibraryData());
    }
  }, [status, dispatch]);

  return (
    <section className='library'>
      <div className='container'>
        <div className='sectionTitleBlack'>
          <h3 className='title'>Bizning kutubxona</h3>
        </div>
        <div className='libraryItems'>
          <LibraryCard
            image={imagesBooks}
            alt='imagesBooks'
            contentText={libraryData[0]?.name}
            totalIcon='icon-Soundwave'
            totalNumber={libraryData[0]?.count}
            btnText='Kitoblarni olish'
            btnLink='/library'
            infoText={!libraryData[0]?.infoText ? "" : libraryData[0]?.infoText}
            infoTitle={!libraryData[0]?.infoTitle ? "" : libraryData[0]?.infoTitle}
          />
          <LibraryCard
            image={imagesVideo}
            alt='imagesVideo'
            contentText={libraryData[1]?.name}
            totalIcon='icon-Soundwave'
            totalNumber={libraryData[1]?.count}
            btnText='Videoni olish'
            btnLink='/library'
            infoText={!libraryData[1]?.infoText ? "" : libraryData[1]?.infoText}
            infoTitle={!libraryData[1]?.infoTitle ? "" : libraryData[1]?.infoTitle}
          />
          <LibraryCard
            image={imagePdf}
            alt='imagePdf'
            contentText={libraryData[2]?.name}
            totalIcon='icon-Soundwave'
            totalNumber={libraryData[2]?.count}
            btnText='PDF olish
'
            btnLink='/library'
            infoText={!libraryData[2]?.infoText ? "" : libraryData[2]?.infoText}
            infoTitle={!libraryData[2]?.infoTitle ? "" : libraryData[2]?.infoTitle}
          />
          <LibraryCard
            image={imageAudio}
            alt='imageAudio'
            contentText={libraryData[3]?.name}
            totalIcon='icon-Soundwave'
            totalNumber={libraryData[3]?.count}
            btnText='Audioni olish
'
            btnLink='/library'
            infoText={!libraryData[3]?.infoText ? "" : libraryData[3]?.infoText}
            infoTitle={!libraryData[3]?.infoTitle ? "" : libraryData[3]?.infoTitle}
          />
          {/* <LibraryCard
            image={item.photoUrl ? "https://proskill-academy.com/api/" + `${item.photoUrl}` : ""}
            alt={item.name}
            contentText={item.name}
            totalIcon='icon-Soundwave'
            totalNumber={item.count}
            btnText='Приобрести'
            btnLink='/library'
            infoText={!item.infoText ? "" : item.infoText}
            infoTitle={!item.infoTitle ? "" : item.infoTitle}
          /> */}
          {/* <LibraryCard
            image={item.photoUrl ? "https://proskill-academy.com/api/" + `${item.photoUrl}` : ""}
            alt={item.name}
            contentText={item.name}
            totalIcon='icon-Soundwave'
            totalNumber={item.count}
            btnText='Приобрести'
            btnLink='/library'
            infoText={!item.infoText ? "" : item.infoText}
            infoTitle={!item.infoTitle ? "" : item.infoTitle}
          /> */}
          {/* <LibraryCard
            image={item.photoUrl ? "https://proskill-academy.com/api/" + `${item.photoUrl}` : ""}
            alt={item.name}
            contentText={item.name}
            totalIcon='icon-Soundwave'
            totalNumber={item.count}
            btnText='Приобрести'
            btnLink='/library'
            infoText={!item.infoText ? "" : item.infoText}
            infoTitle={!item.infoTitle ? "" : item.infoTitle}
          /> */}
        </div>
      </div>
    </section>
  );
};

export default Library;
