import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TopBanner from "./components/TopBanner";
import ScrollToTop from "./router/skrollToTop";
import MainPage from "./pages/Main";
import CoursesPage from "./pages/Courses";
import CoursePage from "./pages/Course/CoursePage";
import LibraryPage from "./pages/Library";
import NewsPage from "./pages/News";
import AboutPage from "./pages/About";
import ContactsPage from "./pages/Contacts";
import PaymentPage from "./pages/Payment";
import AuthPage from "./pages/Auth";
import OtpForm from "./pages/Auth/OtpForm";
import ClickPage from "./pages/ClickPage";
import PaymePage from "./pages/PaymePage";
import PaymentOtp from "./components/PaymentOtp";
import PaymeOtp from "./components/PaymeOtp";
import "./assets/styles/global.sass";
import "./assets/styles/colors.css";
import "./index.scss";
import HandleTrailingSlash from "./components/HandleTrailingSlash"; 

const App = () => {
  return (
    <BrowserRouter>
      <HandleTrailingSlash />
      <div className="appBody">
        <ToastContainer />
        <TopBanner />
        <Header />
        <ScrollToTop />
        <div className="appContent">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/courses/:id" element={<CoursePage />} />
            <Route path="/library" element={<LibraryPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/otp" element={<OtpForm />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/click" element={<ClickPage />} />
            <Route path="/payme" element={<PaymePage />} />
            <Route path="/paymentOtp" element={<PaymentOtp />} />
            <Route path="/paymeOtp" element={<PaymeOtp />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
