import React from "react";
import "./styles.sass";

const Achivements = () => {
  return (
    <div className='achivements'>
      <div className='achivementsContainer'>
        <div className='achivementsHeading'>
          <div className='sectionTitleBlack'>
            <h3 className='title'>Bizning yutuqlarimiz</h3>
          </div>
          <div className='achivementsText'>
            <p className='text'>
              Kompaniyamiz uch yildan ortiq vaqt davomida savdo sohasida muvaffaqiyatli faoliyat yuritib, ajoyib
              yutuqlar va doimiy o‘sishni namoyish etmoqda. Shu vaqt ichida biz mijozlarimizga faqat yetakchi ishlab
              chiqaruvchilarning yuqori sifatli mahsulotlarini taklif qilib, mahsulot assortimentini sezilarli darajada
              kengaytirishga muvaffaq bo‘ldik.
            </p>
          </div>
        </div>
        <div className='achivementsCards'>
          <div className='achivementsItems'>
            <div className='achivementItem'>
              <div className='itemIcon'>
                <i className='icon icon-shield'></i>
              </div>
              <div className='itemTitle'>
                <h4 className='title'>Mijozlar bazasining o‘sishi</h4>
              </div>
              <div className='itemText'>
                <p className='text'>
                  Yuqori darajadagi xizmat ko‘rsatish va shaxsiy yondashuv tufayli mijozlarimiz sonini 50% ga oshirshga
                  muvafaq bo‘ldik.
                </p>
              </div>
            </div>
            <div className='achivementItem'>
              <div className='itemIcon'>
                <i className='icon icon-search'></i>
              </div>
              <div className='itemTitle'>
                <h4 className='title'>Savdo geografiyasini kengaytirish</h4>
              </div>
              <div className='itemText'>
                <p className='text'>
                  Biz 3 ta yangi mamlakatda, shu jumladan Rossiya, Qozog‘iston va Turkiyada vakolatxonalar ochdik, bu
                  bizga kengroq iste’molchilarga xizmat ko‘rsatish imkonini berdi.
                </p>
              </div>
            </div>
          </div>
          <div className='achivementsItems'>
            <div className='achivementItem'>
              <div className='itemIcon'>
                <i className='icon icon-star-full'></i>
              </div>
              <div className='itemTitle'>
                <h4 className='title'>Innovatsiyalarni joriy etish</h4>
              </div>
              <div className='itemText'>
                <p className='text'>
                  Biz savdo jarayonida ilg‘or texnologiyalarni, shu jumladan ombor operatsiyalarini avtomatlashtirishni
                  va mijozlar bilan munosabatlarni boshqarish (CRM) tizimini joriy qildik, bu ish samaradorligini
                  oshirdi va buyurtmalarni qayta ishlash vaqtini qisqartirdi.
                </p>
              </div>
            </div>
            <div className='achivementItem'>
              <div className='itemIcon'>
                <i className='icon icon-medal'></i>
              </div>
              <div className='itemTitle'>
                <h4 className='title'>Soha rahbarlari bilan hamkorlik</h4>
              </div>
              <div className='itemText'>
                <p className='text'>
                  Biz asosiy sheriklar bilan strategik hamkorlik tuzdik, bu esa bizga mijozlarimizga eksklyuziv
                  mahsulotlar va shartlarni taklif qilish imkonini berdi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achivements;
