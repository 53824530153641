import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "./styles.sass";
import "./styles.sass";
import newsImage from "../../assets/images/news1.jpg";
import newsImage2 from "../../assets/images/news2.jpg";
const NewsSlider = () => {
  return (
    <div>
      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        loop={true}
        autoplay={{ delay: 100 }}
        freeMode={true}
        modules={[Autoplay]}
        speed={3200}
        className='newsSlider'
        breakpoints={{
          911: {
            slidesPerView: 1.5,
          },
        }}
      >
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              Bizning kompaniyamiz o'z hissasini qo'shish uchun mahalliy itlar boshpanasiga ko'ngilli sayohat uyushtirdi
              to'rt oyoqli do'stlar uchun sharoitlarni yaxshilash. Bu kun bizning korporativ ijtimoiy hayotimizning
              muhim qismiga aylandi mas'uliyatli bo'lib, barcha ishtirokchilarda unutilmas taassurot qoldirdi.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage2}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              9-may kuni korxonamiz oziq-ovqat savatlarini yig‘ishtirib, faxriylarni ziyorat qilib, o‘z izhorlarini
              bildirishdi Ulug 'Vatan urushidagi jasoratlari uchun minnatdorchilik va hurmat. Bu kun hamma uchun muhim
              voqeaga aylandi xodimlar va tadbir ishtirokchilari.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              Bizning kompaniyamiz o'z hissasini qo'shish uchun mahalliy itlar boshpanasiga ko'ngilli sayohat uyushtirdi
              to'rt oyoqli do'stlar uchun sharoitlarni yaxshilash. Bu kun bizning korporativ ijtimoiy hayotimizning
              muhim qismiga aylandi mas'uliyatli bo'lib, barcha ishtirokchilarda unutilmas taassurot qoldirdi.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage2}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              Bizning kompaniyamiz o'z hissasini qo'shish uchun mahalliy itlar boshpanasiga ko'ngilli sayohat uyushtirdi
              to'rt oyoqli do'stlar uchun sharoitlarni yaxshilash. Bu kun bizning korporativ ijtimoiy hayotimizning
              muhim qismiga aylandi mas'uliyatli bo'lib, barcha ishtirokchilarda unutilmas taassurot qoldirdi.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              Bizning kompaniyamiz o'z hissasini qo'shish uchun mahalliy itlar boshpanasiga ko'ngilli sayohat uyushtirdi
              to'rt oyoqli do'stlar uchun sharoitlarni yaxshilash. Bu kun bizning korporativ ijtimoiy hayotimizning
              muhim qismiga aylandi mas'uliyatli bo'lib, barcha ishtirokchilarda unutilmas taassurot qoldirdi.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='imageContainer'>
            <img
              src={newsImage2}
              alt='newsImage'
              className='img'
            />
            <p className='imageText'>
              Bizning kompaniyamiz o'z hissasini qo'shish uchun mahalliy itlar boshpanasiga ko'ngilli sayohat uyushtirdi
              to'rt oyoqli do'stlar uchun sharoitlarni yaxshilash. Bu kun bizning korporativ ijtimoiy hayotimizning
              muhim qismiga aylandi mas'uliyatli bo'lib, barcha ishtirokchilarda unutilmas taassurot qoldirdi.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default NewsSlider;
