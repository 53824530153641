import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const NavBar = ({ burgerIsOpen }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const isMainPageActive = location.pathname === "/";
  const isPriceLinkActive = () => location.hash === "#price";
  return (
    <nav className={`nav ${burgerIsOpen ? "activeMenu" : ""}`}>
      <div className='navContainer'>
        <div className='navBox'>
          <div className='links'>
            <Link
              to='./'
              // className={`navLink ${({ isActive }) => (isActive ? "active" : "")}`}
              className={`navLink ${isMainPageActive && !isPriceLinkActive() ? "active" : ""}`}
            >
              Asosiy
            </Link>
            <NavLink
              to='./about'
              className={`navLink ${({ isActive }) => (isActive ? "active" : "")}`}
            >
              Biz haqimizda
            </NavLink>
            <NavLink
              to='./courses'
              className={`navLink ${({ isActive }) => (isActive ? "active" : "")}`}
            >
              Kurslar
            </NavLink>
            <NavLink
              to='./library'
              className={`navLink ${({ isActive }) => (isActive ? "active" : "")}`}
            >
              Kutubxona
            </NavLink>
            <NavLink
              to='./news'
              className={`navLink ${({ isActive }) => (isActive ? "active" : "")}`}
            >
              Yangiliklar
            </NavLink>
            {location.pathname === "/about" ||
            location.pathname === "/news" ||
            location.pathname === "/courses" ||
            location.pathname === "/contacts" ||
            location.pathname === "/library" ||
            location.pathname === "/payment" ? (
              <Link
                to='/#price'
                className={`navLink ${isPriceLinkActive() ? "active" : ""}`}
              >
                Narxlar
              </Link>
            ) : (
              <Link
                to='#price'
                className={`navLink ${isMainPageActive && isPriceLinkActive() ? "active" : ""}`}
              >
                Narxlar
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
{
  /* <Link
              className='navLink'
              to='./contacts'
            >
              Контакты
            </Link> */
}
export default NavBar;
